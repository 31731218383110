
import React from 'react';

import NoImage from "../../images/noimage.png";
import "./shopcard.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";


function ShopCard(props) {

    let image = props.image ? props.image : NoImage;
    let name = props.name ? props.name : "Shop Name";
    let desc = props.description ? props.description : "Short shop description";
    // let address = props.address ? props.address : "Shop Address";
    // let citystate = props.citystate ? props.citystate : "City, State";

    return (
        <React.Fragment>
            <div className={"shopcard-width"}>
                <div className={"shopcard-max-width shopcard-min-height"}>
                    <img alt="Not Provided By Vendor" src={image} className={"shopcard-max-width"}/>
                </div>
                <div className={"shopcard-text-div shopcard-font"}>
                    <div className={"shopcard-text-block shopcard-text-block-vendor "}>
                        {name}
                    </div>
                    <div className={"shopcard-text-block shopcard-text-block-description"}>
                        {desc}
                    </div>
                    {/*<div className={"shopcard-padtop shopcard-text-block shopcard-text-block-address"}>*/}
                    {/*    <div>{address}</div>*/}
                    {/*    <div>{citystate}</div>*/}
                    {/*</div>*/}
                    <hr color={"#DDDDDD"}/>
                    <div className={"shopcard-font shopcard-padleft-small shopcard-text-block-gift"}>
                        <LocalShippingIcon style={{width: 14, height: 14, color: '#999999'}}/><span className={"shopcard-desc-text-positioining"}>Free Shipping</span>
                        <CardGiftcardIcon className={"shopcard-padleft"} style={{width: 14, height: 14, color: '#999999'}}/><span className={"shopcard-desc-text-positioining"}>Free Gift</span>
                    </div>
                </div>
                <div className={"shopcard-font shopcard-max-width shopcard-centered shopcard-padbottom"}>
                    <button className={"shopcard-button shopcard-font"}>View Shop</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopCard;