
import React from 'react';

import "./ItemTableMenu.css";
import {Paper, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";

function ItemTableMenu(props) {

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
            <div className={"item-table-menu-root"}>
                <div className={"item-table-menu-menu-item"}>
                    <Paper elevation={1} className={"item-table-menu-paper"} sx={{backgroundColor: "#f6aa2c"}}>
                        <div className={"item-table-menu-selector"}>Item Library</div>
                    </Paper>
                </div>
                <div className={"item-table-menu-menu-item"}>
                    <Paper elevation={1} className={"item-table-menu-paper"}>
                        <div className={"item-table-menu-selector"}>Categories</div>
                    </Paper>
                </div>
                <div className={"item-table-menu-menu-item"}>
                    <Paper elevation={1} className={"item-table-menu-paper"}>
                        <div className={"item-table-menu-selector"}>Custom Attributes</div>
                    </Paper>
                </div>
            </div>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default ItemTableMenu;
