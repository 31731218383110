import React from 'react';

import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import BackgroundImage from "../../components/image/BackgroundImage";
import "../../util/ElLocaleUtil";
import "./LaunchHome.css";

import COMMUNITY from "../../images/community.png";
import SHOPLOCAL from "../../images/shop-local.png";
import IMAGE from "../../images/portsmith.png";
import {createTheme, ThemeProvider} from "@mui/material";
import ShopCard from "../../components/card/shopcard";
import Carousel from "react-material-ui-carousel";

function LaunchHome(props) {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#555555',
                main: '#333333',
                dark: '#000000',
                contrastText: '#fff',
            }
        },
        typography: {
            fontFamily: 'Montserrat',
            button: {
                textTransform: 'none',
            }
        }
    });

    let locale = props.locale ? props.locale : "Unknown Locale";
    //let description = props.description ? props.description : "No Description Available";

    let content = <React.Fragment>
        <div className={"product-max-width product-margin-top"}>
            <div className={"launch-title-font launch-centered launch-bold"}>{locale}</div>
            <div className={"launch-join-the-community"}>
                <div className={"launch-community-div"}>
                    <img className={"launch-community-img"} alt="Community" src={COMMUNITY}/>
                </div>
            </div>
        </div>
    </React.Fragment>

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <ElHeader/>
                <BackgroundImage image={IMAGE} height={250} content={content}/>
                <div className={"launch-shop-local"}>
                    <img className={"launch-shop-local-img"} alt="Shop Local" src={SHOPLOCAL}/>
                    <div className={"launch-favorite-shops"}>
                        Your favorite shops and artisans
                    </div>
                    <div className={"launch-favorite-shops"}>
                        Support YOUR community!
                    </div>
                </div>
                <div className={"launch-meet-locals"}>
                    MEET SOME OF THE LOCAL BUSINESSES
                </div>
                <div className={"launch-local-business-div"}>
                    <div className={"launch-local-businesses"}>
                        <Carousel sx={{width: "100%", textAlign: "center"}}>
                            <div className={"launch-card-carousel"}>
                                <span className={"launch-local-card"}>
                                    <ShopCard
                                        // className={"launch-local-card"}
                                        image={"https://jpolk-public.s3.amazonaws.com/vendors/bliss.png"}
                                        name={"Bliss"}
                                        description={"Women's Clothing"}
                                        address={"Market Street"}
                                        citystate={"Portsmouth, NH"}
                                    />
                                </span>
                                <span className={"launch-local-card"}>
                                    <ShopCard
                                        image={"https://jpolk-public.s3.amazonaws.com/vendors/society.png"}
                                        name={"Société"}
                                        description={"Boutique Tea, Skincare, Candles, and CBD Shop"}
                                        address={"51 Ceres Street"}
                                        citystate={"Portsmouth, NH"}
                                    />
                                </span>
                            </div>
                            <div className={"launch-card-carousel"}>
                                <span className={"launch-local-card"}>
                                    <ShopCard
                                        // className={"launch-local-card"}
                                        image={"https://jpolk-public.s3.amazonaws.com/vendors/EmAndElle.png"}
                                        name={"Emme and Elle"}
                                        description={"Women's Clothing and Wellness"}
                                        address={"36 Main St"}
                                        citystate={"Durham, NH"}
                                    />
                                </span>
                                <span className={"launch-local-card"}>
                                    <ShopCard
                                        // className={"launch-local-card"}
                                        image={"https://jpolk-public.s3.amazonaws.com/board-and-brush.png"}
                                        name={"Board and Brush"}
                                        description={"Fine handicrafts, crafts, hobbies"}
                                        address={"95 Brewery Lane"}
                                        citystate={"Portsmouth, NH"}
                                    />
                                </span>
                                <span className={"launch-local-card"}>
                                    <ShopCard
                                        // className={"launch-local-card"}
                                        image={"https://jpolk-public.s3.amazonaws.com/portsouth-fabric.png"}
                                        name={"Portsmouth Fabric Company"}
                                        description={"Shopping, Crafts & Hobbies"}
                                        address={"112 Penhallow St"}
                                        citystate={"Portsmouth, NH"}
                                    />
                                </span>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <ElFooter/>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default LaunchHome;