
import React from 'react';

import "./PaymentInformation.css";
import ElFooter from "../../components/footer/elfooter";
import ElHeader from "../../components/header/elheader";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditCardIcon from '@mui/icons-material/CreditCard';

function PaymentInformation(props) {

    const contactInfo = {
        payee: "Joy Curth",
        email: "joy.curth@gmail.com",
        phone: "858-882-7459"
    }

    const savedPayments = [
        {
            fourDigits: 1319,
            expiration: "3/25"
        },
        {
            fourDigits: 9307,
            expiration: "3/27"
        }
    ];

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"paymentinfo-root"}>
                <div className={"paymentinfo-title"}>
                    Payment Information
                </div>
                <div className={"paymentinfo-contactcard"}>
                    <div className={"paymentinfo-container"}>
                        <div className={"paymentinfo-contactinfo"}>Payment Contact Information</div>
                        <div className={"paymentinfo-userinfo"}>
                            <div style={{flexGrow: 1}}>
                                <div>{contactInfo.payee}</div>
                                <div>{contactInfo.email}</div>
                                <div>{contactInfo.phone}</div>
                            </div>
                            <div className={"paymentinfo-arrowcontainer"}>
                                <ArrowForwardIosIcon fontSize={"tiny"}/>
                            </div>
                        </div>

                        <br/>
                        <br/>
                        <hr style={{marginRight: "20px", borderTop: "solid 1px #DDDDDD"}}/>
                        <br/>
                        <div className={"paymentinfo-savedpayments"}>
                            Saved Payments
                        </div>
                        <div>
                            {savedPayments.map((card) => {
                                return (
                                        <div className={"paymentinfo-cardinfo"}>
                                            <CreditCardIcon/><span className={"paymentinfo-spans"}>*** {card.fourDigits} {card.expiration}</span>
                                            <span style={{float: "right", marginRight: "30px"}}>
                                                <ArrowForwardIosIcon style={{marginTop: "7px"}} fontSize={"tiny"}/>
                                            </span>
                                        </div>
                                );
                            })}
                        </div>
                        <div className={"paymentinfo-paymentmethodadd"}>
                            <a href={"https://ellocale.com"}>Add payment method</a>
                        </div>
                        <hr style={{marginTop: "30px", marginRight: "20px", marginBottom: "30px", borderTop: "solid 1px #DDDDDD"}}/>
                    </div>

                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    )
}

export default PaymentInformation;