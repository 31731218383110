import React from 'react';

import "./PaymentMethod.css";
import {Paper, TextField} from "@mui/material";

export default function PaymentMethod(props) {

    return (
        <React.Fragment>
            <div className={"paymentmethod-container"}>
                <Paper elevation={10} style={{padding: "20px", borderRadius: "15px"}}>
                <div className={"paymentmethod-title"}>
                    Let's Add Your Payment Method
                </div>

                <div className={"paymentmethod-details"} style={{marginTop: "30px"}}>
                    <div style={{display: "flex", width: "100%"}}>
                        <div style={{
                            fontWeight: "700",
                            flexGrow: "3.0",
                            fontSize: "12px",
                            marginRight: "5px",
                            display: "grid",
                            alignContent: "center"
                        }}>Credit Card Number:
                        </div>
                        <TextField variant={"outlined"} placeholder={"XXXX-XXXX-XXXX-XXXX"} size={"small"}/>
                    </div>
                </div>
                <div className={"paymentmethod-details"}>
                    <div style={{display: "flex", width: "100%"}}>
                        <div style={{
                            fontWeight: "700",
                            flexGrow: "3.0",
                            fontSize: "12px",
                            marginRight: "5px",
                            display: "grid",
                            alignContent: "center"
                        }}>Expiration Date:
                        </div>
                        <TextField variant={"outlined"} size={"small"} placeholder={"MM/YYYY"}/>
                    </div>
                </div>
                <div className={"paymentmethod-details"} style={{marginBottom: "15px"}}>
                    <div style={{display: "flex", width: "100%"}}>
                        <div style={{
                            fontWeight: "700",
                            flexGrow: "3.0",
                            fontSize: "12px",
                            marginRight: "5px",
                            display: "grid",
                            alignContent: "center"
                        }}>CVV
                        </div>
                        <TextField variant={"outlined"} size={"small"} placeholder={"XXX"}/>
                    </div>
                </div>
                </Paper>
            </div>
        </React.Fragment>
    )
}
