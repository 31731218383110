
import React from 'react';

import "./OrderFulfillmentCard.css";
import NOIMAGE from "../../../images/noimage-white.png";
import {Button} from "@mui/material";

function OrderFulfillmentCard(props) {

    validateProperties(props);

    function validateProperties(props) {
        console.log("Validating properties");

        if (props) {
            console.log("Properties found");
        }
        else {
            console.warn("No properties passed...");
        }
    }

    // this will be a server call
    const orderDetails = {
        placed: "April 17, 2024",
        delivered: "April 18th, 2024",
        arriving: null,
        status: "Arrived",
        total: "101.94",
        shipTo: "Joy Curth",
        placedBy: "Joy Curth",
        orderNum: "123-4567-899",
        detailLink: "https://ellocale.com/",
        summaryLink: "https://ellocale.com/",
        itemImage: NOIMAGE,
        productLink: "https://ellocale.com",
        productName: "Tea of Tranquilitea 1oz Jar",
        vendorName: "Societe",
        vendorLink: "https://ellocale.com"
    };

    return (
        <React.Fragment>
            <div className={"ordercard-fulfillment-root"}>
                <div className={"ordercard-fulfillment-columns"}>
                    <div className={"ordercard-fulfillment-column"}>
                        <div className={"ordercard-fulfillment-column-header"}>
                            Order Placed
                        </div>
                        <div>
                            {orderDetails.placed}
                        </div>
                    </div>
                    <div className={"ordercard-fulfillment-column ordercard-fulfillment-responsive-optional"}>
                        <div className={"ordercard-fulfillment-column-header "}>
                            Total
                        </div>
                        <div>
                            ${orderDetails.total}
                        </div>
                    </div>
                    <div className={"ordercard-fulfillment-column ordercard-fulfillment-responsive-optional"}>
                        <div className={"ordercard-fulfillment-column-header "}>
                            Ship To
                        </div>
                        <div>
                            {orderDetails.shipTo}
                        </div>
                    </div>
                    <div className={"ordercard-fulfillment-column ordercard-fulfillment-responsive-optional"}>
                        <div className={"ordercard-fulfillment-column-header "}>
                            Placed By
                        </div>
                        <div>
                            {orderDetails.placedBy}
                        </div>
                    </div>
                    <div className={"ordercard-fulfillment-column"}>
                        <div className={"ordercard-fulfillment-column-header"}>
                            Order # {orderDetails.orderNum}
                        </div>
                        <div className={"ordercard-fulfillment-bluecolor"}>
                            <a href={orderDetails.detailLink}>View Order Details</a>
                        </div>
                        <div>
                            <a href={orderDetails.summaryLink}>Printable Order Summary</a>
                        </div>
                    </div>
                </div>
                <div className={"ordercard-fulfillment-status-div"}>
                    <div className={"ordercard-fulfillment-status"}>
                        {orderDetails.delivered ? "Delivered " +orderDetails.delivered : "Arriving " + orderDetails.arriving}
                    </div>
                    <div className={"ordercard-fulfillment-status-update"}>
                        {orderDetails.status}
                    </div>
                    <div className={"ordercard-fulfillment-status-item-details"}>
                        <span className={"ordercard-fulfillment-tiny-column"}>
                            <img className={"ordercard-fulfillment-image"} width="100" alt={"product"} src={NOIMAGE}/>
                        </span>
                        <span className={"ordercard-fulfillment-large-column"}>
                            <div>
                                <a href={orderDetails.productLink}>{orderDetails.productName}</a>
                            </div>
                            <div>
                                <a href={orderDetails.vendorLink}>{orderDetails.vendorName}</a>
                            </div>
                            <div className={"ordercard-fulfillment-margin-top-small"}>
                            <Button style={{fontSize: "10px", height: "25px", fontWeight: "700", textDecoration: "none"}} size={"small"} variant={"contained"} color="primary">
                                <span>Buy It Again</span>
                            </Button>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default OrderFulfillmentCard;