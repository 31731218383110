
import React from 'react';

import "./VendorAccountPage.css";
import AccountCard from "../../components/card/accountcard";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StarsIcon from '@mui/icons-material/Stars';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PaymentsIcon from '@mui/icons-material/Payments';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

function VendorAccountPage(props) {

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"vendoraccount-root"}>
                <div className={"vendoraccount-title"}>
                    My Account
                </div>
                <hr/>
                <div className={"vendoraccount-row"}>
                    <span className={"vendoraccount-item"}>
                        <AccountCard title={"Your Orders"} desc={"Track, return, or manage your orders"}
                                     icon={<span className={"vendoraccount-icon"}><ReceiptLongIcon/></span>}/>
                    </span>
                    <span className={"vendoraccount-item-right"}>
                        <AccountCard title={"Login and Security"} desc={"Edit login, name, and mobile number"}
                                     icon={<span className={"vendoraccount-icon"}><LockOpenIcon/></span>}/>
                    </span>
                </div>
                <div className={"vendoraccount-row"}>
                    <span className={"vendoraccount-item"}>
                        <AccountCard title={"Your Favorites"} desc={"Manage your list of favorite shops, locales"}
                                     icon={<span className={"vendoraccount-icon"}><StarsIcon/></span>}/>
                    </span>
                    <span className={"vendoraccount-item-right"}>
                        <AccountCard title={"Your Payments"} desc={"Manage payment methods and settings"}
                                     icon={<span className={"vendoraccount-icon"}><PaymentsIcon/></span>}/>
                    </span>
                </div>
                <div className={"vendoraccount-row"}>
                <span className={"vendoraccount-item"}>
                        <AccountCard title={"El Locale Business Account"} desc={"Set up and manage your business"}
                                     icon={<span className={"vendoraccount-icon"}><AccountBalanceIcon/></span>}/>
                    </span>
                    <span className={"vendoraccount-item-right"}>
                        <AccountCard title={"Gift Cards"} desc={"View balance or redeem a card"}
                                     icon={<span className={"vendoraccount-icon"}><CardGiftcardIcon/></span>}/>
                    </span>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    )
}

export default VendorAccountPage;