
import React from 'react';
import "./ItemTableOptions.css";
import {Button, MenuItem, TextField} from "@mui/material";
import {ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";
import {MOCK_ITEM_OPTIONS} from "../../util/MockData";

function ItemTableOptions(props) {

    const options = props.options ? props.options : MOCK_ITEM_OPTIONS;

    function buildOptionSelect(option, idx) {

        console.log("Building " + idx);

        return <React.Fragment>
            <span style={{marginRight: "10px"}}>
            <TextField select
                id="demo-simple-select"
                value={100} size={"small"}
                // label={option.name}
                // onChange={handleChange}
            >
                <MenuItem value={100}><span className={"item-table-options-titleheader"}>{option.name}</span></MenuItem>

                {
                    option.items.map((item, idx) => {
                        return <MenuItem value={idx}>{item.name}</MenuItem>
                    })
                }
            </TextField>
            </span>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
            <div className={"item-table-options-root"}>
                <span style={{display: "flex"}}>
                    {options.map((option, idx) => {
                        return buildOptionSelect(option, idx);
                    })}
                    <span style={{flexGrow: 5}}>
                        <span style={{float: "right"}}>
                            <span>
                            <TextField select
                                id="demo-simple-select"
                                value={100}
                                size={"small"}
                                // label={"Actions"}
                                // onChange={handleChange}
                            >
                                <MenuItem value={100}><span className={"item-table-options-titleheader"}>Actions</span></MenuItem>
                            </TextField>
                            </span>
                            <span style={{marginLeft: "10px"}}>
                                <Button style={{fontSize: "16px", height: "39px", fontWeight: "700", width: "130px", position: "relative", top: "0px"}}
                                        size={"small"} variant={"contained"} color="primary">
                                    <span>Create Item</span>
                                </Button>
                            </span>
                        </span>
                    </span>
                </span>

            </div>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default ItemTableOptions;