
import React from 'react';

import "./Cart.css";

import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SellIcon from '@mui/icons-material/Sell';

import {createTheme, FormControl, InputAdornment, TextField, ThemeProvider} from "@mui/material";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import Sellable from "../../components/product/sellable";

import SupportedPayment from "../../images/supported-payment.png";

function Cart(props) {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#777777',
            }
        }
    });

    let itemCount = props.items && props.items.length ? props.items.length : 0;

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"cart-frame"}>
                <div className={"cart-continueshopping"}>
                    <a href={"https://ellocale.com"}>&lt; Continue Shopping</a>
                </div>
                <div className={"cart-yourcart"}>
                    Your cart
                </div>
                <div className={"cart-padtop cart-display"}>
                    <div className={"cart-leftpane"}>
                        <div className={"cart-tinytext-bold cart-margin-bottom-small"}>YOUR ORDER ({itemCount} ITEMS)</div>
                        <div className={"cart-item-div"}>
                            <Sellable/>
                            <Sellable/>
                            <Sellable/>
                        </div>
                        <div>
                            <button className={"cart-add-more-buttom"}>Add more items</button>
                        </div>
                    </div>
                    <div className={"cart-rightpane"}>
                        <div className={"cart-float-left"}>
                            <span className={"cart-tinytext-bold"}>HOW TO GET IT</span>
                            <span className={"cart-float-right cart-smalltext cart-padtext-top"}><a
                                href={"https://ellocale.com" }>Edit</a></span>
                        </div>
                        <div className={"cart-padtop cart-float-left"}>
                            <ThemeProvider theme={theme}>
                                <LocalShippingIcon fontSize={"small"} color={"primary"}/>
                                <span className={"cart-smalltext cart-padtext-bottom"}>Shipping</span>
                            </ThemeProvider>
                        </div>
                        <div>
                            <hr className={"cart-separator"}/>
                        </div>
                        <div className={"cart-max-width cart-padleft-small cart-padtop-small"}>
                            <FormControl variant="standard"
                                         className={"cart-max-width cart-aligned-center cart-padleft-small"}>
                                <TextField
                                    className={"cart-textfield-coupon"}
                                    id="cart-coupon-input"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SellIcon/></InputAdornment>,
                                    }}
                                    defaultValue="Add coupon or gift card"
                                    size={"small"}
                                />
                            </FormControl>
                        </div>
                        <div className={"cart-padtop-small"}>
                            <hr className={"cart-separator"}/>
                        </div>
                        <div>
                            <div className={"cart-float-left"}>
                                <span className={"cart-smalltext cart-float-left"}>Subtotal</span>
                                <span className={"cart-float-right cart-smalltext"}>$26.00</span>
                            </div>
                            <div className={"cart-float-left"}>
                                <span className={"cart-smalltext"}>Estimated Shipping</span>
                                <span className={"cart-float-right cart-smalltext"}>$0.00</span>
                            </div>
                            <div className={"cart-float-left"}>
                                <span className={"cart-smalltext"}>Estimated Taxes</span>
                                <span className={"cart-float-right cart-smalltext"}>$0.00</span>
                            </div>
                            <div className={"cart-float-left"}>
                                <span className={"cart-smalltext cart-font-bold"}>Estimated Order Total</span>
                                <span className={"cart-float-right cart-font-bold cart-smalltext"}>$26.00</span>
                            </div>
                            <div className={"cart-notification"}>
                                Additional taxes and fees will be calculated at checkout
                            </div>
                            <div className={"cart-button-div"}>
                                <button className={"cart-button"}>Continue to payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"cart-supported-payment-div"}>
                <img className={"cart-supported-payment-image"} src={SupportedPayment} alt={"Supported Payments"}/>
            </div>
            <ElFooter/>
        </React.Fragment>
    );
}

export default Cart;