import {TextField} from "@mui/material";
import React from "react";

export default function buildVariantOption(name, placeholder, onUpdate) {

    console.log("Have update function: " + onUpdate ? "true" : "false");

    return (
        <div style={{display: "flex"}}>
            <div className={"variation-label"}>
                {name}
            </div>
            <div className={"variation-value"}>
                <TextField
                    style={{width: "100%"}}
                    placeholder={placeholder}
                    size={"small"}
                    onChange={onUpdate}
                />
            </div>
        </div>
    );

}