
import React from 'react';

import "./mission.css";

class MissionStatement extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className={"mission-layout mission-transform"}>
                    <div className={"mission-content-container"}>
                        OUR MISSION IS TO MAKE IT EASY FOR YOU TO SHOP IN YOUR COMMUNITY
                        <br/>
                        <br/>
                        IT CAN BE CONVENIENT TO SHOP LOCAL
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MissionStatement;