import React from 'react';

import "./BankAccount.css";
import ElLocaleText from "../../images/ellocale_small.png";
import {Paper, TextField} from "@mui/material";

export default function BankAccount(props) {

    return (
        <React.Fragment>
            <div className={"bankaccount-container"}>

                {/*ElLocale Title*/}
                <div>
                    <img src={ElLocaleText} height={40}/>
                </div>
                <Paper elevation={10} style={{padding: "30px", marginTop: "20px", borderRadius: "15px"}}>
                    <div className={"bankaccount-details"}>
                        Add Your Bank Account Details
                    </div>
                    <div style={{marginTop: "40px"}}>
                        <div className={"bankaccount-inputrow"}>
                            <div style={{
                                display: "grid",
                                alignContent: "center",
                                flexGrow: "1.0",
                                textAlign: "left",
                            }}>
                                Account Name
                            </div>
                            <div>
                                <TextField size={"small"} placeholder={"Bank of America"} style={{flexGrow: "2.0"}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <div className={"bankaccount-inputrow"}>
                            <div style={{
                                display: "grid",
                                alignContent: "center",
                                flexGrow: "1.0",
                                textAlign: "left",
                            }}>
                                Routing #
                            </div>
                            <div>
                                <TextField size={"small"} placeholder={"1234-5678-9"} style={{flexGrow: "2.0"}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <div className={"bankaccount-inputrow"}>
                            <div style={{
                                display: "grid",
                                alignContent: "center",
                                flexGrow: "1.0",
                                textAlign: "left",
                            }}>
                                Account #
                            </div>
                            <div>
                                <TextField size={"small"} placeholder={"8-12 Digits"} style={{flexGrow: "2.0"}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <div className={"bankaccount-inputrow"}>
                            <div style={{
                                display: "grid",
                                alignContent: "center",
                                flexGrow: "1.0",
                                textAlign: "left",
                            }}>
                                Verify Account #
                            </div>
                            <div>
                                <TextField size={"small"} placeholder={"8-12 Digits"} style={{flexGrow: "2.0"}}/>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>

        </React.Fragment>
    )
}
