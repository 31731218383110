
import React from 'react';

import "./ItemCategoryDialog.css";
import {Button, Checkbox, InputAdornment, TextField, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import NoImage from "../../images/noimage-white.png"
import {SampleCategoryData} from "../../util/MockData";

function CategoryItem(props) {

    const name = props.name ? props.name : "No Name Provided";
    const image = props.image ? props.image : NoImage;

    return (
        <React.Fragment>
            <div className={"item-category-item-root"}>
                <div className={"item-category-item-small"}>
                    <img src={image} width="40" className={"item-category-item-image-border"} alt={"category_image"}/>
                </div>
                <div className={"item-category-item-name item-category-item-center-text"}>
                    <span>{name}</span>
                </div>
                <div className={"item-category-item-small "}>
                    <div className={"item-category-item-float-right"}>
                        <Checkbox />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function ItemCategoryDialog(props) {

    const items = props.items ? props.items : SampleCategoryData;

    const actionFunction = props.action ? props.action : null;
    const closeFunction = props.close ? props.close : null;

    function handleClose() {
        if (closeFunction) {
            closeFunction();
        }
    }

    function handleAction() {
        if (actionFunction) {
            actionFunction();
        }
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
                <div className={"item-category-dialog-root"}>

                    {/*Top Button Bar*/}
                    <div className={"item-category-dialog-buttonbar"}>
                    <span className={"item-category-left-column"}>
                        <Button
                                onClick={handleClose}
                                style={{fontSize: "12px", height: "37px", fontWeight: "700", width: "60px"}}
                                size={"small"}
                                variant={"contained"}
                                color={"grayed"}>
                            <CloseIcon/>
                        </Button>
                    </span>
                        <span className={"item-category-right-column"}>
                        <Button
                            onClick={handleAction}
                            style={{fontSize: "12px", height: "39px", fontWeight: "700", width: "60px", float: "right"}}
                            size={"small"} variant={"contained"} color={"primary"}>
                            Done
                        </Button>
                    </span>
                    </div>

                    <div className={"item-category-margin-top"}>
                        {/*Title*/}
                        <span className={"item-category-title"}>Categories</span>
                    </div>
                    <div className={"item-category-margin-top"}>
                        {/*Title*/}
                        <span className={"item-category-select-category-title"}>Select categories this item will be part of</span>
                    </div>

                    <div className={"item-category-margin-top item-category-searchbar"}>
                        <TextField
                            id="input-with-icon-textfield"
                            style={{width: "100%"}}
                            placeholder={"Search"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    {/*List Items*/}
                    <div style={{overflowY: "scroll", height: "360px", borderRadius: "10px", marginTop: "10px"}}>
                        {items.map((item) => {
                            return <CategoryItem key={"id_"+item.id} name={item.name} image={item.image} id={item.id}/>
                        })}
                    </div>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default ItemCategoryDialog;