
import React from 'react';

import "./OrderUp.css";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import OrderUpDetails from "./OrderUpDetails";
import OrderUpPay from "./OrderUpPay";

export default function OrderUp(props) {

    if (!props) {
        console.log("No properties passed to OrderUp");
    }

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"orderup-root"}>
                <div className={"orderup-column"}>
                    <OrderUpDetails/>
                </div>
                <div style={{marginLeft: "5px", marginRight: "5px"}}/>
                <div className={"orderup-column"}>
                    <OrderUpPay/>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    );
}