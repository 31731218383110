import {Box} from "@mui/material";
import React from "react";


function ProductViewport(props) {

    let items = props.items ? props.items : [];

    return (
        <React.Fragment>
                <div className={"product-viewport"}>
                    <div className={"product-center-margins"}>
                        <Box justifyContent={"center"} alignContent={"center"} alignSelf={"center"} alignItems="center"
                             display={"inline-flex"} flexWrap="wrap">
                            {
                                items
                            }
                        </Box>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default ProductViewport;