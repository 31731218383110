import React from 'react';

import "./ProductPage.css";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import BackgroundImage from "../../components/image/BackgroundImage";
import "../../util/ElLocaleUtil";

import IMAGE from "../../images/portsmith.png";
import {createTheme, ThemeProvider} from "@mui/material";
import SideMenu from "../../components/menu/SideMenu";

import {categories, gifts, local, myShops, myLocales} from "../../util/MockData";
import ProductToolBar from "../../components/toolbar/ProductToolBar";
import MapMockup from "../../images/map-mockup.png";

function ProductPage(props) {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#555555',
                main: '#333333',
                dark: '#000000',
                contrastText: '#fff',
            }
        },
        typography: {
            fontFamily: 'Montserrat',
            button: {
                textTransform: 'none',
            }
        }
    });

    let locale = props.locale ? props.locale : "Unknown Location";
    let description = props.description ? props.description : "Unknown Region";
    let detailsPane = props.detailsPane ? props.detailsPane : <div></div>;
    let showMap = props.showMap ? props.showMap : false;

    let content = <React.Fragment>
        <div className={"product-max-width product-margin-top"}>
            <div className={"product-title-font product-centered product-bold"}>{locale}</div>
            <div className={"product-desc-font product-centered product-bold"}>{description}</div>
        </div>
    </React.Fragment>

    function handleChange() {

    }

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
            <ElHeader sidemenus={[
                                    {cssshow: "product-left-sidemenu-header", name: "Categories", list: categories},
                                    {cssshow: "product-left-sidemenu-header", name: "Gifts", list: gifts},
                                    {cssshow: "product-left-sidemenu-header", name: "Local", list: local},
                                    {cssshow: "product-right-sidemenu-header", name: "My Shops", list: myShops},
                                    {cssshow: "product-right-sidemenu-header", name: "My Locales", list: myLocales}
                                ]}/>
            <BackgroundImage image={IMAGE} height={100} content={content}/>
            <div className={"product-pad-top product-display-grid"}>
                <div className={"product-large-pad-top product-margin-left product-left-sidemenu"}>
                    <SideMenu title={"Category"} links={categories}/>
                    <br/>
                    <SideMenu title={"Gifts"} links={gifts}/>
                    <br/>
                    <SideMenu title={"Local"} links={local}/>
                </div>
                <div className={"product-max-width"}>
                    <ProductToolBar theme={theme} onChange={handleChange}/>
                    {detailsPane}
                </div>
                {showMap ?
                    <React.Fragment>
                        <div className={"product-margin-top product-right-sidemenu"}>
                            <img src={MapMockup} alt={"Google Map"}/>
                        </div>
                    </React.Fragment> :
                        <div className={"product-large-pad-top product-right-side-menu product-right-sidemenu"}>
                            <SideMenu title={"My Shops"} links={myShops}/>
                            <br/>
                            <SideMenu title={"My Locales"} links={myLocales}/>
                        </div>
                }
            </div>
            <ElFooter/>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default ProductPage;