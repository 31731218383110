
import React from 'react';

import "./OptionCheck.css";
import {Checkbox, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function OptionCheckModal(props) {

    const TITLE = props.title ? props.title : "No Title Specified";
    const DESC = props.desc ? props.desc : "No description provided.";
    const DATA = props.data ? props.data : [];

    validateProperties(props);

    function validateProperties(props) {
        if (!props) {
            console.warn("No properties passed...");
        }
    }

    function handleChange(checkbox) {

    }

    function buildItem(icon, name, key, selected) {

        let checked = selected ? selected : false;
        return (
                <div className={"category-list-item"} key={key}>
                    {icon ? <div><img style={{position: "relative", top: "5px", border: "solid 1px lightgray", borderRadius: "5px"}} src={icon} alt={"Icon"} width={32} height={32}/></div> : ""}
                    <span className={"category-list-desc"}>{name}</span>
                    <span><Checkbox checked={checked} onChange={handleChange} name={key}/></span>
                </div>
        );
    }

    return (
        <React.Fragment>
            {/*Root*/}
            <div className={"category-root"}>
                {/*Title*/}
                <div className={"category-title"}>
                    {TITLE}
                </div>
                {/*Explanation*/}
                <div className={"category-explanation"}>
                    {DESC}
                </div>
                <div className={"category-searchbar"}>
                    <TextField
                        id="category-search-icon"
                        // style={{flexGrow: "10.0"}}
                        className={"category-search-field"}
                        placeholder={"Search"}
                        size={"small"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={"category-list-container"}>
                    {
                        DATA.map((row) => {
                            return buildItem(row.icon, row.name, row.key, row.selected);
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

