
import React from 'react';
import Card from '@mui/material/Card';


import "./orderdetailsbundle.css";
import OrderDetailCard from "../../components/card/orderdetailcard";

function OrderDetailsBundle(props) {

    let arriving = props.arriving ? props.arriving : "Soon"
    let seller = props.seller ? props.seller : "Unknown Vendor";

    return (
        <React.Fragment>
            <div className={"ordercard-root"}>
                <Card>
                    <div className={"ordercard-arriving"}>Arriving {arriving}, from {seller}</div>
                    <div className={"ordercard-estimate"}>Estimated Delivery</div>
                    <div className={"ordercard-marginleft"}>
                        <OrderDetailCard/>
                        <OrderDetailCard/>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default OrderDetailsBundle;