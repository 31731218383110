
import React from "react";
import {CssBaseline, Grid, ThemeProvider} from "@mui/material";
import { THEME } from "../../util/ElLocaleUtil";
import ElLocale from "../../images/ellocale_text_only.png";

function InterestPageFull() {


    return (
        <React.Fragment>
            <CssBaseline>
                <ThemeProvider theme={THEME}>
                <Grid container alignContent={"center"} textAlign={"center"}>
                    <Grid item md={3} sm={12} xs={12}>
                        <div className={"interest-bg-color"}>
                            <img className={"interest-img-margins"} alt={"El Locale"} src={ElLocale}/>
                        </div>
                    </Grid>
                    <Grid item md={9} sm={12} xs={12}>
                        <div className={"interest-bg-color-white interest-font-base interest-complete-minheight"}>
                            <div className={"interest-font-complete interest-success-margin"}>Signup Successful!</div>
                            <div className={"interest-success-margin interest-font-local"}>
                                You're on your way to easily shopping local!
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </ThemeProvider>
            </CssBaseline>
        </React.Fragment>
    );
}

export default InterestPageFull;