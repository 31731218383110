
import React from 'react';

import "./orderdetailcard.css";

import NoImage from "../../images/noimage-white.png";
import {IconButton} from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';

function OrderDetailCard(props) {

    let itemImage = props.image ? props.image : NoImage;
    let itemInfo = props.iteminfo ? props.iteminfo : "No Item Details Provided";
    let itemSoldBy = props.soldby ? props.soldby : "Unknown Vendor";
    let itemPrice = props.price ? props.price : "$0.00";

    return (
        <React.Fragment>
            <div className={"orderdetailcard-root"}>
                <div className={"orderdetailcard-image-div"}>
                    <img className={"orderdetailcard-image"} src={itemImage} alt={"Item"}/>
                </div>
                <div className={"orderdetailcard-detail-div"}>
                    <div className={"orderdetailcard-iteminfo"}>{itemInfo}</div>
                    <div className={"orderdetailcard-itemsoldby"}>Sold by: {itemSoldBy}</div>
                    <div className={"orderdetailcard-itemsoldby orderdetailcard-price"}>{itemPrice}</div>
                    <div className={"orderdetailcard-itemsoldby"}><span className={"orderdetailcard-condition"}>Condition:</span> New</div>
                    <div className={"orderdetailcard-orderagain"}>
                        <IconButton aria-label="delete" size="small"
                                    style={{border: "solid 1px #DDDDDD", borderRadius: "5px", fontSize: "12px"}}>
                            <SyncIcon fontSize="inherit" />
                            <span className={"orderdetailcard-fontbold"}>&nbsp;Buy it again</span>
                        </IconButton>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default OrderDetailCard;