
import React from 'react';

import "./GiftCard.css";
import {Button, TextField, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

export default function GiftCard(props) {

    const BALANCE = props.balance ? props.balance : "N/A";

    if (!props) {
        console.log("No properties passed.");
    }

    return (
        <React.Fragment>
            <ElHeader/>
            <ThemeProvider theme={THEME}>
            <div className={"giftcard-root"}>
                <div className={"giftcard-header"}>
                    Redeem a Gift Card
                </div>
                <div className={"giftcard-claimbox"}>
                    <div className={"giftcard-claimfont"}>
                        Enter claim code (dashes not required)
                    </div>
                    <div className={"giftcard-textandbutton"}>
                        <div className={"giftcard-textandbutton-left"}>
                            <TextField
                                className={"giftcard-field"}
                                label=""
                                defaultValue=""
                                size={"small"}
                            />
                        </div>
                        <div className={"giftcard-textandbutton-right"}>
                            <Button style={{fontSize: "14px", height: "37px", fontWeight: "800"}} size={"small"}
                                    variant={"contained"} color="primary">
                                <span>Apply to Your Account</span>
                            </Button>
                        </div>
                    </div>
                    <div className={"giftcard-balance"}>
                        Current Gift Card Balance: {BALANCE}
                    </div>
                    <div>
                        <a href={"https://ellocale.com"}><span
                            className={"giftcard-link"}>View Balance History</span></a>
                    </div>
                    <div style={{marginTop: "20px", marginBottom: "20px"}}>
                        <hr/>
                    </div>
                    <div className={"giftcard-terms"}>
                        Once applied to your El Locale account, the entire gift card amount will be added
                        to your gift card balance. Your gift card balance, including "El Locale Reload", does
                        not expire and can't be tranferred to other accounts, used to buy other gift cards, or, as
                        except as required by law, redeemed for cash. If you recently paid for part of a purchase
                        using a Gift Card, and that item has not yet shipped, then any new funds added to your gift
                        Card balance up to the full purchase amount will be applied to that order once it's shipped.
                        Review terms and conditions.
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <hr/>
                    </div>
                    <div className={"giftcard-help"}>
                        If you are having issues redeeming your gift card, visit our <a href={"https://ellocale.com"}> Gift Card Redemption Issues</a> help page.
                    </div>
                </div>
            </div>
                <ElFooter/>
            </ThemeProvider>
        </React.Fragment>
    );
}