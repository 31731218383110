
import React, {useState} from 'react';
import {doFetch} from "../../util/ElLocaleUtil";
import ElFooter from "../../components/footer/elfooter";
import {CssBaseline} from "@mui/material";

import "./Unsubscribe.css";
import WelcomeEmail from "../../images/welcome-email.png";


function Unsubscribe(props) {

    const [confirmUnsub, setConfirmUnsub] = useState(false);

    checkForCompletion();

    function checkForCompletion() {
        const queryParameters = new URLSearchParams(window.location.search)

        if (queryParameters.has("interestId") && queryParameters.has("unsubscribe")) {
            const interestId = queryParameters.get("interestId");

            return unsubscribe(interestId);
        }
    }

    function unsubscribe(interestId) {

        let payload = {
            interestId: interestId
        };

        doFetch("interest/unsubscribe", payload, (response) => handleUnsubscribe(response));
    }

    function handleUnsubscribe(response) {
        if (response.status !== "failure") {
            setConfirmUnsub(true);
        }
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            {
                confirmUnsub ? <div>Totally Unsubscribed</div> :
                    <div className={"unsub-centered"}>
                        <div className={"unsub-font unsub-fontinfo unsub-centered"}>
                            Data Removal Instructions
                        </div>
                        <div className={"unsub-fixedwidth unsub-margins unsub-font"}>
                            Removing your data from our site is easy!  At present, we only have interested
                            users subscribed with their first and last name, as well as their email address.
                            If you have subscribed to our interest page for updates, you should have received
                            a welcome email (pictured below).  Simply click the "unsubscribe" link at the bottom of
                            the email (circled in <span className={"unsub-redfont"}>RED</span>) to remove all of your
                            information from our site.  We will be sad to see you go, but we understand your need for
                            data privacy!
                        </div>
                        <img alt="Welcome" height="500" className={"unsub-bordered unsub-margin-top unsub-margin-bottom"} src={WelcomeEmail}/>
                        <ElFooter/>
                    </div>
            }
        </React.Fragment>
    );
}

export default Unsubscribe;