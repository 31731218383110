
import React, {useState} from 'react';

import "./ChipContainer.css";
import {Button, Chip, Stack, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";
import CustomAttributeModal from "../modals/CustomAttribute/CustomAttribute";
import ContentModal from "../../components/modals/ContentModal";

export default function ChipContainer(props) {

    const [modalOpen, setModalOpen] = useState(false);
    const [chips, setChips] = useState(props.chips ? props.chips : {});
    const [renders, setRenders] = useState(1);
    const title = props.title ? props.title : "No Title Specified";
    const width = props.width ? props.width : "500px";
    const height = props.height ? props.height : "300px";
    const desc = props.desc ? props.desc : "No description available";
    const modal = <CustomAttributeModal onEdit={onModalEdit}/>;
    const buttonText = props.buttonText ? props.buttonText : "Add";

    let value = "";

    function onModalConfirm() {
        setModalOpen(false);

        if (value && value.length > 0) {

            if (chips.hasOwnProperty(value)) {
                console.log("Duplicate chip detected");
            } else {
                let copy = chips;
                copy[value] = value;
                setChips(copy);
            }
        }
    }

    function onModalClose() {
        setModalOpen(false);
    }

    function onModalEdit(obj) {
        value = obj.target.value;
    }

    function handleClickChip() {
        console.log("Click Chip");
    }

    function handleDeleteChip(chip) {

        let copy = chips;
        delete copy[chip];
        setChips(copy);
        setRenders(renders+1);
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
            <div className={"chipcontainer-root"}>
                <div style={{width: width, height: height, margin: "auto"}}>
                    <div className={"chipcontainer-titlebar"}>
                        <div className={"chipcontainer-bold chipcontainer-title"}>{title}</div>
                        <div className={"chipcontainer-button"}>
                            <Button size="small" variant="contained" color={"primary"} onClick={() => setModalOpen(true)}>
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                    <div className={"chipcontainer-description"}>
                        {desc}
                    </div>
                    <div className={"chipcontainer-chips"}>
                        <Stack direction="row" spacing={1}>
                            {
                                Object.keys(chips).map( (key) => {
                                    return <Chip
                                        key={key}
                                        label={key}
                                        onClick={handleClickChip}
                                        onDelete={() => handleDeleteChip(key)}
                                    />
                                })
                            }
                        </Stack>
                    </div>
                </div>
            </div>
            </ThemeProvider>
            <div>
                <ContentModal width={450} height={150} buttonText={"Add"} open={modalOpen} close={onModalClose} edit={onModalConfirm}
                              content={modal}/>
            </div>
        </React.Fragment>
    );
}
