
import React from 'react';
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import OrderConfirmationHeader from "../../components/orders/OrderConfirmationHeader";

import "./OrderConfirmation.css";
import Card from "@mui/material/Card";
import OrderDetailsBundle from "../../bundles/order/orderdetailsbundle";
import OrderSummary from "../../components/card/ordersummary";

function OrderConfirmation(props) {

    // confirmation
    let shippingAddress = props.shipping ? props.shipping : "Pick Up";
    let savings = props.savings ? props.savings : "$0.00";
    let orderNumber = props.ordernum ? props.ordernum : "Not Available";

    // order details
    let subtotal = props.subtotal ? props.subtotal : "$0.00";
    let shipping = props.shipping ? props.shipping : "$0.00";
    let beforeTax = props.beforetax ? props.beforetax : "$0.00";
    let estimatedTax = props.estimatedTax ? props.estimatedTax : "$0.00";
    let total = props.total ? props.total : "$0.00";

    // when we actually have orders, I'll incorporate this into the JSX
    let orders = props.orders ? props.orders : [];

    validateOrder(shippingAddress, savings, orderNumber, subtotal, shipping, beforeTax, estimatedTax, total, orders);

    function validateOrder(
        shippingAddress,
        savings,
        orderNumber,
        subtotal,
        shipping,
        beforeTax,
        estimatedTax,
        total,
        orders) {

    }

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"orderconfirmationpage-root"}>
                <div>
                    <Card>
                        <OrderConfirmationHeader/>
                    </Card>
                </div>
                <div style={{marginTop: "5px"}}>
                    <div className={"orderconfirmationpage-details"}>
                        <div className={"orderconfirmationpage-col1"}>
                            <div className={"orderconfirmationpage-ordercard"}>
                                <OrderDetailsBundle/>
                            </div>
                            <div className={"orderconfirmationpage-ordercard"}>
                                <OrderDetailsBundle/>
                            </div>
                        </div>
                        <div className={"orderconfirmationpage-col2"}>
                            <OrderSummary/>
                        </div>
                    </div>
                </div>

            </div>
            <ElFooter/>
        </React.Fragment>
    );
}

export default OrderConfirmation;