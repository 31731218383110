
import React from 'react';


class BackgroundImage extends React.Component {

    state = {
        image : null
    };

    constructor(props) {
        super(props);

        this.state.image = props.image;
        this.state.height = props.height ? props.height : 300;
        this.state.content = props.content ? props.content : "";
    }

    render() {

        let height = this.state.height;

        return (
            <React.Fragment>
                <div style={{ backgroundImage: `url(${this.state.image})`,
                    backgroundRepeat: 'no-repeat', backgroundSize:"cover",
                    backgroundPositionY: "center", height: `${ height }px` }} >

                    {this.state.content}
                </div>
            </React.Fragment>
        );
    }
}

export default BackgroundImage;