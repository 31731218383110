
import React from 'react';

import "./BusinessPane.css";
import {myBusinesses} from "../../util/MockData";

function BusinessPane(props) {

    validateProperties(props);

    function validateProperties(props) {
        console.log("Validating properties");

        if (props) {
            console.log("Properties found");
        }
        else {
            console.warn("No properties passed...");
        }
    }

    return (
        <React.Fragment>
            <div className={"businesspane-totalwidth"}>
                {myBusinesses}
            </div>

        </React.Fragment>
    );
}

export default BusinessPane;