
import React from 'react';
import "./Unit.css";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function UnitModal() {

    return (
        <React.Fragment>
            <div className={"unit-attribute-root"}>
                <div className={"unit-attribute-title"}>Add Unit</div>
                <div className={"unit-description"}>
                    Create additional units to use this item in multiple ways and track stock from a single inventory source.
                    &nbsp;&nbsp;<a href={"https://ellocale.com"}>Learn More</a>
                </div>
                <div className={"unit-selector"}>
                    <TextField
                        select
                        defaultValue={0}
                        variant={"outlined"}
                        className={"unit-selector"}
                        placeHolder={"Unit"}
                    >
                        <MenuItem value={1}><span className={"age-verification-font"}>Sample Unit</span></MenuItem>
                    </TextField>
                </div>
            </div>

        </React.Fragment>
    );
}