
import React from 'react';

import "./ordersummary.css";
import Card from "@mui/material/Card";

function OrderSummary(props) {

    let subtotal = props.subtotal ? props.subtotal : "$0.00";
    let shipping = props.shipping ? props.shipping : "$0.00";
    let beforeTax = props.beforetax ? props.beforetax : "$0.00";
    let estimatedTax = props.estimatedTax ? props.estimatedTax : "$0.00";
    let total = props.total ? props.total : "$0.00";

    return (
        <React.Fragment>
            <div className={"ordersummary-root"}>
                <Card>
                    <div className={"ordersummary-cardcontainer"}>
                        <div className={"ordersummary-bold ordersummary-fontsmall"}>Order Summary</div>
                        <div className={"ordersummary-padtop ordersummary-fonttiny"}>
                            <span>
                                Item(s) Subtotal:
                            </span>
                            <span className={"ordersummary-rightfloat"}>
                                {subtotal}
                            </span>
                        </div>
                        <div className={"ordersummary-fonttiny"}>
                            <span>
                                Shipping & Handling:
                            </span>
                            <span className={"ordersummary-rightfloat"}>
                                {shipping}
                            </span>
                        </div>
                        <div className={"ordersummary-fonttiny"}>
                            <span>
                                Total before tax:
                            </span>
                            <span className={"ordersummary-rightfloat"}>
                                {beforeTax}
                            </span>
                        </div>
                        <div  className={"ordersummary-fonttiny"}>
                            <span>
                                Estimated tax:
                            </span>
                            <span className={"ordersummary-rightfloat"}>
                                {estimatedTax}
                            </span>
                        </div>
                        <div className={"ordersummary-bold ordersummary-padtop ordersummary-fontsmall"}>
                            <span >
                                Grand Total:
                            </span>
                            <span className={"ordersummary-rightfloat"}>
                                {total}
                            </span>
                        </div>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default OrderSummary;