import React from 'react';

import "./QuickAccess.css";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import {createTheme, ThemeProvider} from "@mui/material";


export default function QuickAccess(props) {

    const theme = createTheme({
        palette: {
            primary: {
                light: '#4169E1',
                main: '#4169E1',
                dark: '#4169E1'
            }
        }
    });

    const buildOption = (title, icon, link, indicator) => {
        return <div className={"quickaccess-option"}>
            <div className={"quickaccess-item"}>
                {icon}
            </div>
            <div className={"quickaccess-item quickaccess-lionshare"}>
                {title}
            </div>
            <div className={"quickaccess-item"} style={{marginRight: "20px"}}>
                <ThemeProvider theme={theme}>
                    {indicator ? indicator : ""}
                </ThemeProvider>
            </div>
        </div>
    }

    const options = [
        buildOption("Home", <HomeOutlinedIcon/>, null, null),
        buildOption("Items", <LocalOfferOutlinedIcon/>, null, <Brightness1Icon color={"primary"} className={"quickaccess-icon"}/>),
        buildOption("Team", <GroupsOutlinedIcon/>, null, <Brightness1Icon color={"primary"} className={"quickaccess-icon"}/>),
    ];

    return (
        <React.Fragment>
            <div className={"quickaccess-container"}>
                <div className={"quickaccess-title"}>
                    <div className={"quickaccess-bold quickaccess-titledesc"}>
                        Quick Access
                    </div>
                    <div className={"quickaccess-link quickaccess-bold quickaccess-edit"}>
                        Edit
                    </div>
                </div>
                <div className={"quickaccess-item-container"}>
                    <div>
                        {
                            options.map(option => {
                                return option;
                            })
                        }
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}