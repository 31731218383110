
import React from 'react';

import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {Paper, TextField} from "@mui/material";
import OrderUpItem from "./OrderUpItem";
import {MOCK_ORDER_UP_ITEMS} from "../../util/MockData";

export default function OrderUpDetails(props) {

    const orderDetails = props.orderDetails ? props.orderDetails : {
        availability: "Ready in 15 minutes",
        details: "Estimated pickup time",
        type: "Pickup",
        storeDetails: {
            name: "Unspecified Location",
            address: "123 Some Place, Portsmouth, NH"
        },
        items: MOCK_ORDER_UP_ITEMS,
        subtotal: "17.75",
        taxes: "0.50",
        total: "18.25"
    }

    const store = props.store ? props.store : "Unspecified Store";
    const altType = getAltType(orderDetails.type);


    function getAltType(type) {
        if (type === "Pickup") {
            return "Delivery";
        }

        return "pickup";
    }

    return (
        <React.Fragment>
            <div className={"orderup-details-root"}>
                <div className={"orderup-details-container"}>
                    <div className={"orderup-details-shortflex"}>
                        <ShoppingBagOutlinedIcon className={"orderup-details-icon"}/>
                    </div>
                    <div className={"orderup-details-longflex"}>
                        <div className={"orderup-details-header"}>
                            {store}
                        </div>
                        <div className={"orderup-details-desc"}>
                            Your {orderDetails.type} Order
                        </div>
                    </div>
                </div>
                <div className={"orderup-details-pickupdetails"}>
                    <Paper elevation={2} style={{width: "100%", marginBottom: 20}}>
                        <div style={{padding: "15px", margin: "5px"}}>
                            <div className={"orderup-details-columns"}>
                                <div className={"orderup-details-column orderup-details-font"}>
                                    {orderDetails.type} details
                                </div>
                                <div className={"orderup-details-column orderup-details-alignright orderup-details-bluebold"}>
                                    Switch to {altType}
                                </div>
                            </div>
                            <div className={"orderup-details-tricol orderup-tricol-margingap"}>
                                <div className={"orderup-details-tricol-tiny"}>
                                    <AccessTimeOutlinedIcon/>
                                </div>
                                <div className={"orderup-details-tricol-large"}>
                                    <div className={"orderup-details-bold-info"}>
                                        {orderDetails.availability}
                                    </div>
                                    <div className={"orderup-details-desc-small"}>
                                        {orderDetails.details}
                                    </div>
                                </div>
                                <div className={"orderup-details-tricol-tiny"}>
                                    <ChevronRightOutlinedIcon/>
                                </div>
                            </div>
                            <div style={{marginTop: 20}}>
                                <hr/>
                            </div>
                            <div className={"orderup-details-tricol orderup-tricol-margingap"}>

                                    <div className={"orderup-details-tricol-tiny"}>
                                        <StorefrontOutlinedIcon/>
                                    </div>
                                    <div className={"orderup-details-tricol-large"}>
                                        <div className={"orderup-details-bold-info"}>
                                            {orderDetails.storeDetails.name}
                                        </div>
                                        <div className={"orderup-details-desc-small"}>
                                            {orderDetails.storeDetails.address}
                                        </div>
                                    </div>
                                    <div className={"orderup-details-tricol-tiny"}>
                                        <ChevronRightOutlinedIcon/>
                                    </div>
                            </div>
                        </div>
                    </Paper>
                    <Paper elevation={2} style={{width: "100%"}}>
                        <div style={{padding: "15px", margin: "0px"}}>
                            <div className={"orderup-details-dualcol-container"}>
                                <div className={"orderup-details-dualcol-col orderup-details-bold-info orderup-details-margins"}>
                                    Your Order
                                </div>
                                <div
                                    style={{marginRight: "10px"}}
                                    className={"orderup-details-dualcol-col orderup-details-bluebold orderup-details-alignright"}>
                                    Add More Items
                                </div>
                            </div>
                        </div>
                        <div style={{marginLeft: 20, marginRight: 20, borderTop: "solid 1px #BBBBBB"}}/>
                        <div style={{padding: "0px", margin: "0px", width: "100%", maxHeight: (window.innerHeight - 400)+"px", overflowY: "scroll" }}>
                            <div className={"orderup-details-block"}>
                                <div style={{flexGrow: "10.0", maxWidth: "90%"}}>
                                {
                                    orderDetails.items.map((item) => {
                                        return <OrderUpItem item={item}/>
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>
    );
}