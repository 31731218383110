
import React from 'react';
import {Paper, TextField} from "@mui/material";
import {MOCK_ORDER_UP_ITEMS} from "../../util/MockData";
import OrderUpTip from "./OrderUpTip";
import OrderUpReward from "./OrderUpReward";

import GooglePay from "../../images/svg/./GooglePay";
import ApplePay from "../../images/svg/ApplePay";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GenericCard from "../../images/svg/GenericCard";

export default function OrderUpPay(props) {

    const rewards = props.rewards ? props.rewards : [
        {
            id: 1,
            name: "Loyalty Reward",
            amount: "18.40"
        }
    ]

    const orderDetails = props.orderDetails ? props.orderDetails : {
        availability: "Ready in 15 minutes",
        details: "Estimated pickup time",
        type: "Pickup",
        storeDetails: {
            name: "Unspecified Location",
            address: "123 Some Place, Portsmouth, NH"
        },
        items: MOCK_ORDER_UP_ITEMS,
        subtotal: "17.75",
        taxes: "0.50",
        total: "18.25"
    }

    return (
        <React.Fragment>
            <div className={"orderup-details-root"}>
                <div className={"orderup-payment-details orderup-payment-header"}>
                    Payment Details
                </div>
                <Paper elevation={2} style={{padding: "10px", marginTop: "10px"}}>
                    <div className={"orderup-payment-smallmargin-top"}>
                        <TextField placeholder={"Enter promo code"} style={{width: "100%"}}/>
                    </div>
                    <div className={"orderup-item-details orderup-details-margins orderup-payment-smallmargin-top"}>
                        <div className={"orderup-item-details-col-large"}>
                            Subtotal
                        </div>
                        <div className={"orderup-item-details-col-tiny orderup-item-margin-right-small"}>
                            ${orderDetails.subtotal}
                        </div>
                    </div>
                    <div className={"orderup-item-details orderup-details-margins orderup-payment-smallmargin-top"}>
                        <div className={"orderup-item-details-col-large"}>
                            Taxes & Fees
                        </div>
                        <div className={"orderup-item-details-col-tiny orderup-item-margin-right-small"}>
                            ${orderDetails.taxes}
                        </div>
                    </div>
                    <div className={"orderup-item-details orderup-details-margins orderup-payment-largmargin-top"}>
                        <div className={"orderup-item-details-col-large orderup-details-bold-info"}>
                            Total
                        </div>
                        <div className={"orderup-item-details-col-tiny orderup-item-margin-right-small orderup-details-bold-info"}>
                            ${orderDetails.total}
                        </div>
                    </div>
                </Paper>
                <Paper elevation={2} style={{marginTop: "20px"}}>
                    <div className={"orderup-margins-20 orderup-pay-font"}>
                        Tip
                    </div>
                    <div className={"orderup-pay-tip-root"}>
                        <OrderUpTip perc={"10"}/>
                        <OrderUpTip perc={"15"}/>
                        <OrderUpTip perc={"20"}/>
                        <OrderUpTip custom={true}/>
                    </div>
                </Paper>
                <Paper elevation={2} style={{marginTop: "20px", paddingBottom: "20px"}}>
                    <div className={"orderup-margins-20 orderup-pay-font"}>
                        Rewards
                    </div>
                    <div>
                        <OrderUpReward/>
                    </div>
                </Paper>
                <Paper elevation={2} style={{marginTop: "20px", marginBottom: "20px"}}>
                    <div className={"orderup-margins-20 orderup-pay-font"}>
                        Payment Method
                    </div>
                    <div style={{textAlign: "center"}}>
                        <span style={{marginLeft: "0px"}}><ApplePay width={100} height={100}/></span>
                        <span style={{marginLeft: "30px"}}><GooglePay width={100} height={100}/></span>
                        <span style={{marginLeft: "30px"}}><GenericCard width={95} height={95}/></span>
                    </div>
                </Paper>
            </div>
        </React.Fragment>
    )
}