
import React from 'react';

import "./accountcard.css";

function AccountCard(props) {

    let title = props.title ? props.title : "No Title Available";
    let desc = props.desc ? props.desc : "No Description Available";
    let showTitle = props.showTitle ? props.showTitle : true;
    let showDesc = props.showDesc ? props.showDesc : true;
    let width = props.width ? props.width : 400;
    let maxWidth = props.maxWidth ? props.maxWidth : 400;
    let icon = props.icon ? props.icon : "";

    validateProperties(title, desc, showTitle, showDesc, width, maxWidth, icon);

    function validateProperties(title, desc, showTitle, showDesc, width, maxWidth, icon){


        if (title && desc && showTitle && showDesc && width && maxWidth && icon) {
            console.log("Properties validated");
        }
        else {
            console.warn("One or more properties null");
        }
    }

    return (
        <React.Fragment>
            <div className={"accountcard-frame"}>
                <div className={"accountcard-innerframe"}>
                    <div className={"accountcard-padtop accountcard-bold accountcard-margins"}>
                        {icon}
                        {showTitle ? title : ""}
                    </div>
                    <div className={"accountcard-padbottom accountcard-padtop accountcard-margins"}>
                        {showDesc ? desc : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AccountCard;