
import React from 'react';
import "./businesscard.css";
import NoImage from "../../images/noimage-white.png";

function BusinessCard(props) {

    let image = props.image ? props.image : NoImage;
    let name = props.name ? props.name : "Unknown Vendor";
    let desc = props.desc ? props.desc : "Unknown Vendor Type";
    let location = props.location ? props.location : "Unknown Location";
    let opens = props.opens ? props.opens : "Opens soon";

    return (
        <React.Fragment>
            <div className={"businesscard-root businesscard-inline"}>
                <div>
                    <img className={"busincesscard-image"} width="120" src={image} alt={"business logo"}/>
                </div>
                <div className={"businesscard-details"}>
                    <div className={"businesscard-vendor"}>{name}</div>
                    <div className={"businesscard-vendor-details"}>
                        <div>{desc}</div>
                        <div>{location}</div>
                        <div>{opens}</div>
                    </div>
                </div>
                <div className={"businesscard-buttondiv"}>
                    <button className={"businesscard-button"}>See Shop</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BusinessCard;