
import React, {useState} from 'react';
import "./TestModalLoader.css";
import {Box, Button, Paper} from "@mui/material";
import AgeVerificationModal from "../business/modals/AgeVerification/AveVerification";
import OptionCheckModal from "../business/modals/OptionCheck/OptionCheck";
import OptionModal from "../business/modals/Options/Option";
import VariationModal from "../business/modals/Variation/Variation";
import CustomAttributeModal from "../business/modals/CustomAttribute/CustomAttribute";
import UnitModal from "../business/modals/Unit/Unit";
import StockTrackingModal from "../business/modals/StockTracking/StockTracking";
import ManageStockModal from "../business/modals/ManageStock/ManageStock";
import loadModal from "../util/ElLocaleUtil";

import {MOCK_DATA_CATEGORIES} from "../util/MockData";
import {MOCK_DATA_FAVORITES} from "../util/MockData";

export default function TestModalLoader(props) {

    const [modalContent, setModalContent] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    validateProperties(props);

    function validateProperties(props) {
        if (!props) {
            console.warn("No properties passed...");
        }
    }

    function onClose() {
        setModalContent(null);
    }

    function editCallback() {
        console.log("Edit Callback");
    }

    function openAgeVerification() {
        setModalContent(<AgeVerificationModal onEdit={editCallback} open={modalOpen}/>);
        setModalOpen(true);
    }

    console.log(modalOpen);
    console.log(modalContent);

    return (
        <React.Fragment>
            <Box
                display={"flex"}
                alignItems={"center"}
                p={2}
                gap={4}
            >
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => openAgeVerification()}>
                        Age Verification
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<OptionCheckModal onEdit={editCallback} data={MOCK_DATA_CATEGORIES} title={"Categories"} desc={"Select categories the item will be a part of."}/>, 500, 500, "Save", setModalContent, onClose)}>
                        Categories
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<OptionCheckModal onEdit={editCallback} data={MOCK_DATA_FAVORITES} title={"My Favorite Shops & Locales"} desc={"Add or remove a shop or locale from your favorites list."}/>, 500, 500, "Save", setModalContent, onClose)}>
                        Favorites
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<OptionModal onEdit={editCallback}/>, 500, 400, "Set", setModalContent, onClose)}>
                        Options
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<VariationModal onEdit={editCallback}/>, 500, 520, "Save", setModalContent, onClose)}>
                        Variation
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<CustomAttributeModal onEdit={editCallback}/>, 500, 130, "Add", setModalContent, onClose)}>
                        Add custom attribute
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<UnitModal onEdit={editCallback}/>, 500, 230, "Save", setModalContent, onClose)}>
                        Add Unit
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<StockTrackingModal onEdit={editCallback}/>, 700, 500, "Done", setModalContent, onClose)}>
                        Stock Tracking
                    </Button>
                </Paper>
                <Paper>
                    <Button
                        variant={"contained"}
                        onClick={() => loadModal(<ManageStockModal onEdit={editCallback}/>, 700, 500, "Done", setModalContent, onClose)}>
                        Manage Stock
                    </Button>
                </Paper>
            </Box>

            <div>
                {modalContent ? modalContent : ""}
            </div>
        </React.Fragment>
    );
}