import {Button, FormControl, MenuItem, Select} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React from "react";

function ProductToolBar(props) {

    let onChange = props.onChange;
    let theme = props.theme;

    return (
        <React.Fragment>
            <div className={"product-topbar"}>
                <div className={"product-pad-left"}>
                    <div className={"product-results-text"}>
                        <span className={"product-full-bar"}>96 Results</span>
                        <div className={"product-float-right"}>
                            <span className={"product-full-bar"}>
                                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <Select
                                        labelId="product-record-count"
                                        id="product-record-id"
                                        value={10}
                                        //label="Age"
                                        onChange={onChange}
                                        sx={{fontSize: 'small'}}
                                        className={"product-select product-font"}
                                        color={theme.primary}
                                    >
                                        <MenuItem key="mi_10" className={"product-font"}
                                                  value={10}>Show 10</MenuItem>
                                        <MenuItem key="mi_25" className={"product-font"}
                                                  value={25}>Show 25</MenuItem>
                                        <MenuItem key="mi_50" className={"product-font"}
                                                  value={50}>Show 50</MenuItem>
                                        <MenuItem key="mi_100" className={"product-font"}
                                                  value={51}>Show 100</MenuItem>
                                    </Select>
                                </FormControl>
                            </span>
                            <span className={"product-button-transform"}>
                                <Button color={theme.primary} size="small" variant="outlined"
                                        startIcon={<TuneIcon/>}>
                                    Filters
                                </Button>
                            </span>
                        </div>
                    </div>
                    <div className={"product-full-bar"}>
                        <FiberManualRecordIcon fontSize={"tiny"}
                                               className={"product-float-left product-dot"}/>
                        <span
                            className={"product-results-font"}>results within 10 miles of 03801&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href={"choose"}>choose area</a></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ProductToolBar;