
import React, {useState} from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import "./itemcard.css";
import {Button, styled} from "@mui/material";

function ItemCard(props) {

    const [highlighted, setHighlighted] = useState(false);

    const CartButton = styled(Button)({
        textTransform: 'none',
        fontSize: '12px',
        marginTop: '10px',
        backgroundColor: "#f6aa2c",
        fontWeight: "800",
        borderRadius: "20px",
        fontFamily: "Montserrat"
    });

    const DEFAULT_IMAGE = "/coming_soon.jpeg";

    let image = props.image ? props.image : DEFAULT_IMAGE;
    let description = props.description ? props.description : "No Description Available";
    let seller = props.seller ? props.seller : "El Locale Vendor";
    let price = props.price ? props.price : "$0.00";
    let width = props.width ? props.width : 200;
    let height = props.height ? props.height : 250;
    let imageDimensions = props.imageDimensions ? props.imageDimensions : {width: 200, height: 80};

    function handleMouseOver(e) {
        setHighlighted(true);
    }

    function handleMouseLeave(e) {
        setHighlighted(false);
    }

    return (
        <React.Fragment>
            <div onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseOver} className={"itemcard-card"} style={{width: width+"px", height: height+"px"}}>
                <div>
                    <img alt="Item Display" src={image} height={imageDimensions.height} className={"itemcard-align-center"}/>
                </div>
                <div className={"itemcard-font itemcard-descriptions"}>
                    <div className={"itemcard-seller itemcard-bold"}>
                        {seller}
                        <PlayCircleOutlineIcon style={{position: "relative", color: "gray", display: highlighted ? "block" : "none", top: "2px", left: "-5px", float: "right"}}/>
                    </div>
                    <div className={"itemcard-description"}>
                        {description}
                    </div>
                    <div className={"itemcard-price itemcard-bold"}>
                        {price}
                    </div>
                </div>
                <hr className={"itemcard-hr"}/>
                <div className={"itemcard-align-left itemcard-description"}>
                    <LocalShippingIcon style={{width: 14, height: 14, color: '#999999'}}/><span className={"itemcard-desc-text-positioining"}>Free Shipping</span>
                    <CardGiftcardIcon className={"itemcard-padleft"} style={{width: 14, height: 14, color: '#999999'}}/><span className={"itemcard-desc-text-positioining"}>Free Gift</span>
                </div>
                <div className={"itemcard-notextdeco"}>
                    <CartButton className={"itemcard-button itemcard-font itemcard-bold"} variant="outlined">Add To Cart</CartButton>
                    <AddCircleOutlineIcon className={"itemcard-favorite"}/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ItemCard;