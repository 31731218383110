
import React from 'react';
import "./OrderDeliveryPage.css";
import ElHeader from "../../../components/header/elheader";
import ElFooter from "../../../components/footer/elfooter";
import {THEME} from "../../../util/ElLocaleUtil";
import {Button, TextField, ThemeProvider} from "@mui/material";
import OrderFulfillmentCard from "./OrderFulfillmentCard";

function OrderDeliveryPage(props) {

    return (
        <React.Fragment>
            <ElHeader/>
            <ThemeProvider theme={THEME}>
            <div className={"order-deliver-card-root"}>
                <div>
                    <span className={"order-delivery-card-yourorders"}>Your Orders</span>
                    <span className={"order-delivery-card-right"}>
                        <span className={"order-delivery-text-field-span order-deliver-responsive-optional"}>
                            <TextField
                                id="text-searchorder"
                                label="Item, Order, or PO Number"
                                size="small"
                                defaultValue=""
                                />
                        </span>
                        <span className={"order-delivery-card-button order-deliver-responsive-optional"}>
                            <Button style={{fontSize: "10px", height: "37px", fontWeight: "700"}} size={"small"} variant={"contained"} color="primary">
                                <span>Search Orders</span>
                            </Button>
                        </span>
                    </span>
                </div>
                <div className={"order-delivery-menu-bar"}>
                    <span className={"order-delivery-card-bold"}>
                        Orders
                    </span>
                    <span className={"order-deliver-margin-left order-deliver-responsive-optional"}>
                        Buy Again
                    </span>
                    <span className={"order-deliver-margin-left order-deliver-responsive-optional"}>
                        Not Yet Shipped
                    </span>
                    <span className={"order-deliver-margin-left order-deliver-responsive-optional"}>
                        Cancelled Orders
                    </span>
                </div>
                <hr/>
                <div className={"order-delivery-ordersplaced order-delivery-card-bold"}>
                    <span>Viewing Orders Placed</span>
                    <span className={"order-deliver-days"}>Last 30 Days</span>
                </div>
                <div>
                    <OrderFulfillmentCard/>
                    <OrderFulfillmentCard/>
                </div>
            </div>
            </ThemeProvider>
            <ElFooter/>
        </React.Fragment>
    );
}

export default OrderDeliveryPage;