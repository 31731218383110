
import React from 'react';

import "./Option.css";
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChipContainer from "../../components/ChipContainer";
import CustomAttributeModal from "../CustomAttribute/CustomAttribute";

export default function OptionModal(props) {

    validateProperties(props);

    function validateProperties(props) {
        if (!props) {
            console.warn("No properties passed...");
        }
    }

    return (
        <React.Fragment>
            {/*Root*/}
            <div className={"options-relative-header"}>Add Option Set</div>
            <div className={"options-root"}>
                {/*Desc*/}
                <div className={"options-desc"}>
                    Create option sets to group options together.  For example, a set called "Material" may contain options such as "Linen", "Leather", "Vinyl", etc...
                </div>
                <div className={"options-name"}>
                    <span className={"option-set-name"}>
                        Option Set Name:
                    </span>
                    <span className={"option-set-value"}>
                        <TextField
                            id="option-search-icon"
                            style={{width: "100%"}}
                            className={""}
                            placeholder={"Search Existing / Create"}
                            size={"small"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </span>
                </div>
                <div className={"options-seperator"}>
                    <hr/>
                </div>
                <div>
                    <ChipContainer
                        title="Add Options"
                        desc={"Add options such as size, color, material, etc..."}
                        width={"100%"} height={"100%"}
                        modal={<CustomAttributeModal title={"Add Option"} label={"Option"} desc={"Option Name"}/>}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

