
import React from 'react';
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import CountryCodes from "../../util/CountryCodes";

import "./Payment.css";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';

import ApplePayImage from "../../images/apple-pay.png";
import GooglePayImage from "../../images/google-pay.svg";
import {FormControl, InputAdornment, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import US_STATES from "../../util/USStates";
import SellIcon from "@mui/icons-material/Sell";

function Payment(props) {


    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"payment-frame payment-medium-font"}>&lt; Back to Cart</div>
            <div className={"payment-frame payment-margin-top-large"}>
                <div className={"payment-leftframe"}>
                    <div className={"payment-bold payment-checkout"}>Checkout</div>
                    <div className={"payment-small-font payment-margin-top-medium"}>Express Checkout</div>
                    <div>
                        <button className={"payment-button"}>
                            <img alt="ApplePay" className={"payment-applepay"} src={ApplePayImage}/>
                        </button>
                        <button className={"payment-button payment-margin-left"}>
                            <img alt="GooglePay" className={"payment-applepay"} src={GooglePayImage}/>
                        </button>
                    </div>
                    <hr className={"payment-hr"}/>
                    <div className={"payment-bold payment-medium-font"}>Contact</div>
                    <div className={"payment-margin-top-medium payment-frame-responsive"}>
                        <div className={"payment-div"}>
                            <span className={"payment-span-right"}>
                                <TextField
                                id="countrycode"
                                select
                                label="Phone Prefix"
                                size="small"
                                defaultValue="US"
                                className={"payment-phone-prefix"}
                                sx={{
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontFamily: "Montserrat", fontSize: "12px"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                                //helperText="Country Phone Prefix"
                            >
                                {CountryCodes.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.dial_code}&nbsp;&nbsp;{option.name}
                                    </MenuItem>
                                ))}
                            </TextField></span>
                            <TextField
                                id="phonenumber"
                                label="Phone Number"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                                //helperText="Phone Number"
                            />
                        </div>
                        <div className={"payment-div payment-margin-top-tiny"}>
                            <TextField
                                id="emailaddress"
                                label="Email Address (For Receipt)"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                                //helperText="Phone Number"
                            />
                        </div>
                        <div>
                            <hr className={"payment-hr"}/>
                        </div>
                        <div className={"payment-bold payment-medium-font"}>Shipping Address</div>
                        <div
                            className={"payment-div payment-fullsize payment-margin-top-small"}>
                            <TextField
                                id="firstname"
                                label="First Name"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                inputProps={{fontSize: "12px"}}
                                className={"payment-flex"}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                            />
                            <span className={"payment-span-right"}></span>
                            <TextField
                                id="lastname"
                                label="Last Name"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                            />
                        </div>
                        {/*<div className={"payment-phone-div payment-margin-top-small"}>*/}
                        {/*    <TextField*/}
                        {/*        id="countrycode"*/}
                        {/*        select*/}
                        {/*        label="Phone Prefix"*/}
                        {/*        size="small"*/}
                        {/*        defaultValue="US"*/}
                        {/*        className={"payment-phone-flex"}*/}
                        {/*        sx={{*/}
                        {/*            '.MuiInputBase-input': {fontSize: '12px', height: "23px"},*/}
                        {/*            ".MuiInputLabel-root": {fontFamily: "Montserrat", fontSize: "12px"},*/}
                        {/*            ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}*/}
                        {/*        }}*/}
                        {/*        //helperText="Country Phone Prefix"*/}
                        {/*    >*/}
                        {/*        {CountryCodes.map((option) => (*/}
                        {/*            <MenuItem key={option.code} value={option.code}>*/}
                        {/*                {option.dial_code}&nbsp;&nbsp;{option.name}*/}
                        {/*            </MenuItem>*/}
                        {/*        ))}*/}
                        {/*    </TextField>*/}
                        {/*</div>*/}
                        <div className={"payment-div payment-margin-top-tiny"}>
                            <TextField
                                id="address"
                                label="Enter your address here"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                            />
                        </div>
                        <div className={"payment-div payment-margin-top-tiny"}>
                            <TextField
                                id="aptsuitefloor"
                                label="Apt, Suite, Floor, etc."
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                            />
                        </div>
                        <div className={"payment-div payment-margin-top-tiny"}>
                            <TextField
                                id="city"
                                label="City"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                            />
                        </div>
                        <div className={"payment-div payment-margin-top-small"}>
                        <span className={"payment-span-right"}><TextField
                            id="usstate"
                            select
                            label="State"
                            size="small"
                            defaultValue="New Hampshire"
                            className={"payment-phone-prefix"}
                            sx={{
                                '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                ".MuiInputLabel-root": {fontFamily: "Montserrat", fontSize: "12px"},
                                ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                            }}
                        >
                                {US_STATES.map((option) => (
                                    <MenuItem key={option.abbreviation} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField></span>
                            <TextField
                                id="postalcode"
                                label="Postal Code"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                className={"payment-flex"}
                                inputProps={{fontSize: "12px"}}
                                sx={{
                                    ".MuiFormLabel-root": {fontSize: "12px"},
                                    ".MuiFormLabel-colorPrimary": {color: "gray"},
                                    '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                    ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                    ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                }}
                                //helperText="Phone Number"
                            />
                        </div>
                        <hr className={"payment-hr payment-margin-top-medium"}/>
                        <div className={"payment-bold payment-medium-font"}>Shipping Method</div>
                        <div className={"payment-margin-top-medium"}>
                            <span className={"payment-small-font payment-gray-font"}>Please enter a valid address to see available shipping methods</span>
                        </div>
                        <hr className={"payment-hr payment-margin-top-medium"}/>
                        <div className={"payment-bold payment-medium-font"}>Payment</div>
                    </div>
                    <div className={"payment-margin-top-medium payment-border-medium payment-pad-bottom-medium"}>
                        <div className={"payment-margin-medium"}>
                            <div className={"payment-medium-font"}>
                                <span>Credit Card</span>
                                <span className={"payment-float-right payment-top-up"}><PaymentsIcon/></span>
                            </div>
                            <div className={"payment-div payment-margin-top-medium"}>
                                <TextField
                                    id="country"
                                    select
                                    label="Country"
                                    size="small"
                                    defaultValue="US"
                                    className={"payment-flex"}
                                    sx={{
                                        '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                        ".MuiInputLabel-root": {fontFamily: "Montserrat", fontSize: "12px"},
                                        ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                    }}
                                >
                                    {CountryCodes.map((option) => (
                                        <MenuItem key={option.code} value={option.code}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className={"payment-max-width payment-margin-top-small"}>
                                <div className={"payment-div"}>
                                        <span className={"payment-flex-2"}>
                                        <TextField
                                            className={"payment-textfield-std"}
                                            id="creditcarddigits"
                                            defaultValue=""
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><CreditCardIcon/></InputAdornment>,
                                            }}
                                            style={{width: "100%"}}
                                            sx={{
                                                ".MuiFormLabel-root": {fontSize: "12px"},
                                                ".MuiFormLabel-colorPrimary": {color: "gray"},
                                                '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                                ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                                ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                            }}
                                            label="Credit Card Number"
                                            size={"small"}
                                        />
                                        </span>
                                        <span className={"payment-flex-1 payment-max-width-small"}>
                                        <TextField
                                            id="mmyycreditcard"
                                            label="Exp: MM/YY"
                                            defaultValue=""
                                            variant="outlined"
                                            size="small"
                                            inputProps={{fontSize: "12px"}}
                                            sx={{
                                                ".MuiFormLabel-root": {fontSize: "12px"},
                                                ".MuiFormLabel-colorPrimary": {color: "gray"},
                                                '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                                ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                                ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                            }}
                                            //helperText="Phone Number"
                                        />
                                        </span>
                                        <span className={"payment-flex-1 payment-max-width-small"}>
                                        <TextField
                                            id="creditcvv"
                                            label="CVV"
                                            defaultValue=""
                                            variant="outlined"
                                            size="small"
                                            inputProps={{fontSize: "12px"}}
                                            sx={{
                                                ".MuiFormLabel-root": {fontSize: "12px"},
                                                ".MuiFormLabel-colorPrimary": {color: "gray"},
                                                '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                                ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                                ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                            }}
                                            //helperText="Phone Number"
                                        />
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"payment-rightframe payment-margin-top-medium"}>
                    <div className={"payment-margin-left-large"}>
                        <div className={"payment-bold payment-medium-font"}>
                            <span>Order Summary</span>
                            <span className={"payment-float-right payment-margin-bottom"}>
                                <KeyboardArrowDownIcon/>
                            </span>
                        </div>
                        <hr className={"payment-hr payment-margin-top-medium"}/>
                        <div className={"payment-medium-font payment-margin-top-medium"}>
                            <div>
                                <span>Subtotal</span>
                                <span className={"payment-float-right"}>$26.00</span>
                            </div>
                            <div className={"payment-margin-top-tiny"}>
                                <span>Shipping</span>
                                <span className={"payment-float-right"}>--</span>
                            </div>
                            <div className={"payment-margin-top-tiny"}>
                                <span>Taxes</span>
                                <span className={"payment-float-right"}>$0.00</span>
                            </div>
                            <div className={"payment-bold payment-margin-top-tiny"}>
                                <span>Order Total</span>
                                <span className={"payment-float-right"}>$26.00</span>
                            </div>
                        </div>
                        <div className={"payment-max-width payment-margin-top-medium"}>
                            <FormControl variant="standard"
                                         className={"payment-max-width payment-aligned-center payment-margin-left-tiny"}>
                                <TextField
                                    className={"payment-textfield-coupon "}
                                    id="payment-coupon-input"
                                    defaultValue=""
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SellIcon/></InputAdornment>,
                                    }}
                                    style={{marginLeft: "3px"}}
                                    sx={{
                                        ".MuiFormLabel-root": {fontSize: "12px"},
                                        ".MuiFormLabel-colorPrimary": {color: "gray"},
                                        '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                        ".MuiInputLabel-root": {fontSize: "12px", fontFamily: "Montserrat"},
                                        ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                    }}
                                    label="Add coupon or gift card"
                                    size={"small"}
                                />
                            </FormControl>
                        </div>
                        <div style={{marginLeft: "2px"}} className={"payment-margin-top-medium"}>
                            <button className={"payment-payment-button payment-button-text"}>Place Order $26.00</button>
                        </div>
                    </div>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    )
}

export default Payment;