import React from 'react';
import {Component} from "react";
import {Card} from "react-bootstrap";
import {Navigate} from "react-router-dom";

import elLocaleImg from "../../images/ellocale_short.png";
import "./elcard.css";

class ElCard extends Component{

    props = null;

    constructor(props){
        super(props);
        this.props = props;
    }

    render() {

        if (this.state != null && this.state.route != null){
            return (
                <Navigate to={this.state.route} replace={true}/>
            );
        }
        else {
            return this.renderDefaultCard();
        }
    }

    renderDefaultCard(){
        let title = this.props.title != null ? this.props.title : "";
        let body = this.props.body != null ? this.props.body : "Nothing to see here.";
        // let cardImage = this.props.cardImage != null ? this.props.cardImage : elLocaleImg;
        let enabled = this.props.route != null ? true : false;

        return (
            <div className={"elcards"} style={{opacity: (enabled) ? '100%' : '20%' }}>
                <Card className={enabled ? "elcardstyle pointer" : "elcardstyle"}
                      onClick={enabled ? () => this.onClick() : null}>
                    <div style={{background: "#f6aa2c", borderRadius: '15px 15px 0px 0px'}}>
                        <Card.Img variant="top" width={"100px"} src={elLocaleImg} />
                    </div>
                    <Card.Body>
                        <Card.Title style={{fontWeight: "bold", paddingTop: '10px'}}>{title}</Card.Title>
                        <Card.Text style={{paddingLeft: '5px', paddingRight: '5px'}}>
                            {body}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    onClick(){
        this.setState({
           route: this.props.route
        });
    }
}

export default ElCard;