
import React from 'react';

export default function TestPane(props) {

    const width = props.width ? props.width : 800;
    const height = props.height ? props.height : 600;
    const content = props.content ? props.content : "No content to display";

    return (
        <React.Fragment>
            <div style={{width: width, height: height, border: "solid 1px", margin: "auto", textAlign: "center"}}>
                {content}
            </div>
        </React.Fragment>
    )
}
