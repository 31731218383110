
import React from 'react';

import "./OrderUpTip.css";

export default function OrderUpTip(props) {

    const perc = props.perc ? props.perc : "10%";
    const amount = props.amount ? props.amount : "0.00";
    const isCustom = props.custom ? props.custom : false;

    return (
        <React.Fragment>
            <div className={"orderup-tip-root"}>
                <div style={{textAlign: "center", fontWeight: 700}}>
                    {isCustom ? "Custom" : perc + "%"}
                </div>
                <div style={{textAlign: "center"}}>
                    ${amount}
                </div>
            </div>
        </React.Fragment>
    )
}