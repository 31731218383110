
import React from 'react';
import buildVariantOption from "../../../util/Builders";
import "./CustomAttribute.css";

export default function CustomAttributeModal(props) {

    const title = props.title ? props.title : "Add Attribute";
    const label = props.label ? props.label : "Custom Attribute";
    const desc = props.desc ? props.desc : "Attribute Name";
    const onEdit = props.onEdit ? props.onEdit : null;

    return (
        <React.Fragment>
            <div className={"custom-attribute-root"}>
                <div className={"custom-attribute-title"}>{title}</div>
                <div className={"custom-attribute-container"}>
                    {buildVariantOption(label, desc, onEdit)}
                </div>
            </div>

        </React.Fragment>
    );
}