import React from "react";

import "./interest.css";
import ElLocaleSmall from "../../images/ellocale_short.png";

function InterestValidated(props) {

    return (
        <React.Fragment>
            <div className={"interest-override-height interest-padtop interest-padsides interest-font-l"}>
                Your interest in <div><img alt="ElLocale" className={"interest-logo-padding"} src={ElLocaleSmall}/></div>
                has been verified! <br/><br/>Thank you for signing up!

                <div className={"interest-padtop interest-button-font"}>
                    Tell your friends and family!
                </div>
            </div>

        </React.Fragment>
    );
}

export default InterestValidated;