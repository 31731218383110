
import React from 'react';
import "./ItemTablePane.css";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import ItemTableOptions from "./ItemTableOptions";
import ItemTableMenu from "./ItemTableMenu";
import ItemTableView from "./ItemTableView";

function ItemTablePane(props) {

    return (
        <React.Fragment>
            <ElHeader/>

            {/*Root Pane*/}
            <div className={"item-table-pane-root"}>

                {/*Menu Pane*/}
                <span style={{flexGrow: 1}}>
                    <ItemTableMenu/>
                </span>

                {/*Table Pane*/}
                <span style={{flexGrow: 50, marginLeft: 10}}>
                    <ItemTableOptions/>
                    <ItemTableView/>
                </span>
            </div>

            <ElFooter/>
        </React.Fragment>
    );
}

export default ItemTablePane;