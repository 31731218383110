
import React from 'react';

import "./SideMenu.css";

function SideMenu(props) {

    let title = props.title != null ? props.title : "NO TITLE";
    let links = props.links != null ? props.links : [];
    let bgcolor = props.bgcolor != null ? props.bgcolor : "white";

    return (
        <React.Fragment>
            <div className={"sidemenu-maxwidth"} style={{backgroundColor: bgcolor}}>
                <div className={"sidemenu-title-font"}>
                    {title}
                </div>
                <div className={"sidemenu-link-font"}>
                    <ul className={"sidemenu-links"}>
                    {links.map((el, index, arr) => {
                        return <li key={"li_"+index} onMouseEnter={(e) => e.target.style.cursor = "pointer"}>{el.description}</li>
                    })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SideMenu;