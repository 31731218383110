
import React from 'react';

import "./ItemTableView.css";

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {MOCK_DATA_GRID_COLUMNS, MOCK_DATA_GRID_ROWS} from "../../util/MockData";

function ItemTableView(props) {

    return (
        <React.Fragment>
            {/*Root Pane*/}
            <div className={"item-table-view-root"}>
                {/*Menu Row*/}
                <div className={"item-table-body"}>
                    <Box sx={{}}>
                        <DataGrid
                            rows={MOCK_DATA_GRID_ROWS}
                            columns={MOCK_DATA_GRID_COLUMNS}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: "bold"
                                }
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ItemTableView;
