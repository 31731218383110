
import React from 'react';
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

import PortsmouthImage from "../../images/portsmith-harbor.png";

import "./AboutUs.css";

export default function AboutUs(props) {

    if (!props) {
        console.log("No properties passed");
    }

    return (
        <React.Fragment>
            <ElHeader/>
                <div className={"aboutus-root"}>
                    <div className={"aboutus-header"}>
                        About Us
                    </div>
                    <div className={"aboutus-display"}>
                        <div className={"aboutus-image-div"}>
                            <img alt="Porsmouth Harbor" src={PortsmouthImage} className={"aboutus-image"}/>
                        </div>
                        <div className={"aboutus-column"}>
                            Welcome to el locale your trusted partner in supporting local businesses.
                            <br/><br/>
                            Our mission is to empower small businesses by providing a robust e-commerce platform that enhances their online presence and drives growth.
                            <br/><br/>
                            We believe in the power of community and strive to create a marketplace that celebrates local businesses and artisans and promotes sustainable business practices.
                            <br/><br/>
                            We make it easy for you to shop local and keep your community strong.
                            <br/><br/>
                            Join us in supporting local, shopping small, and making a big impact in our communities.
                        </div>
                    </div>
                </div>

                {/*Responsive Only*/}
                <div className={"aboutus-responsive"}>
                    <div className={"aboutus-header"}>
                        About Us
                    </div>
                    <div className={"aboutus-column"}>
                        Welcome to el locale your trusted partner in supporting local businesses.
                        <br/><br/>
                        Our mission is to empower small businesses by providing a robust e-commerce platform that
                        enhances their online presence and drives growth.
                        <br/><br/>
                        We believe in the power of community and strive to create a marketplace that celebrates local
                        businesses and artisans and promotes sustainable business practices.
                        <br/><br/>
                        We make it easy for you to shop local and keep your community strong.
                        <br/><br/>
                        Join us in supporting local, shopping small, and making a big impact in our communities.
                    </div>
                    <div className={"aboutus-image-div"}>
                        <img alt="Portsmouth Image" src={PortsmouthImage} className={"aboutus-image"}/>
                    </div>
                </div>
            <ElFooter/>
        </React.Fragment>
    )

}