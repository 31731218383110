
import React, {useState} from 'react';

import "./AgeVerification.css";
import MenuItem from "@mui/material/MenuItem";
import {FormControl, InputLabel, TextField} from "@mui/material";
import ContentModal from "../../../components/modals/ContentModal";

export default function AgeVerificationModal(props) {

    const [modalOpen, setModalOpen] = useState(props.open ? props.open : true);

    console.log("open: " + modalOpen);

    function onClose() {
        setModalOpen(false);
    }

    validateProperties(props);

    const modal = <React.Fragment>
                            <div className={"age-verification-root"}>
                                {/*Title*/}
                                <div className={"age-verification-title"}>
                                    Age Verification
                                </div>
                                {/*Explanation*/}
                                <div className={"age-verification-explanation"}>
                                    When an item is added to the cart that is age restricted, a prompt will show at checkout to ensure that the buyers date of birth is input.
                                </div>
                                {/*Location*/}
                                <div className={"age-verification-location-title"}>
                                    All locations
                                </div>
                                {/*Location Selector*/}
                                <div className={"age-verification-age-restriction-div"}>
                                    <FormControl className={"age-verification-age-restriction-selector"}>
                                        {/*<InputLabel><span className={"age-verification-font"}>Age Restriction</span></InputLabel>*/}
                                        <TextField
                                            id="age-restriction-selector"
                                            select
                                            defaultValue={1}
                                            variant={"outlined"}
                                            className={"age-verification-age-restriction-selector"}
                                        >
                                            <MenuItem value={1}><span className={"age-verification-font"}>18 and Over</span></MenuItem>
                                            <MenuItem value={2}><span className={"age-verification-font"}>21 and Over</span></MenuItem>
                                        </TextField>
                                    </FormControl>
                                </div>
                                {/*Description*/}
                                <div className={"age-verification-description"}>
                                    Age restrictions are intended to help you comply with loal regulations.  ellocale.com is not responsible for the sale of restricted items to minors.
                                </div>
                            </div>
                        </React.Fragment>

    function validateProperties(props) {
        if (!props) {
            console.warn("No properties passed...");
        }
    }

    console.log("Age Verification");

    return (
        <React.Fragment>
            <ContentModal close={onClose} content={modal} width={500} height={325} onClose={onClose} open={modalOpen}/>
        </React.Fragment>
    )
}

