
import React from 'react';
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

import "./AddressPage.css";
import AddressDetailCard from "./AddressDetailCard";

function AddressPage(props) {

    const pageType = props.pageType ? props.pageType : "Shipping";

    console.log(pageType);

    const sampleData1 = {
        shipTo: "Joy Curth",
        addressLine: "53 Ceres St",
        cityLine: "Portsmouth, NH 03801-3727",
        country: "United States",
        phoneNum: "603-420-9117",
        isDefault: true
    }

    const sampleData2 = {
        shipTo: "Societe Portsmouth LLC",
        addressLine: "51 Ceres St",
        cityLine: "Portsmouth, NH 03801-3727",
        country: "United States",
        phoneNum: "",
        isDefault: false
    }

    const sampleData3 = {
        shipTo: "Societe Yogo Studio",
        addressLine: "52 Ceres St",
        cityLine: "Portsmouth, NH 03801-3727",
        country: "United States",
        phoneNum: "",
        isDefault: false
    }

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"shippingaddress-page-root"}>
                <div className={"shippingaddress-page-location"}>
                    Your Account > {pageType} Addresses
                </div>
                <div className={"shippingaddress-page-youraddresses"}>
                    Your {pageType} Addresses
                </div>
                <div className={"shippingaddress-page-cards"}>
                    <AddressDetailCard isButton={true}/>
                    <AddressDetailCard isButton={false} details={sampleData1}/>
                    <AddressDetailCard isButton={false} details={sampleData2}/>
                    <AddressDetailCard isButton={false} details={sampleData3}/>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    );
}

export default AddressPage;