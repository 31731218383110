
import React from 'react';

import {MOCK_ORDER_UP_ITEM} from "../../util/MockData";

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import "./OrderUpItem.css";
import {Chip} from "@mui/material";

export default function OrderUpItem(props) {

    const item = props.item ? props.item : MOCK_ORDER_UP_ITEM;

    const handleDeleteChip = (id) => {
        console.log("Deleting chip: " + id);
    }

    return (
        <React.Fragment>
            <div className={"orderup-item-root"}>
                <div className={"orderup-item-details"}>
                    <div style={{marginLeft: "10px"}} className={"orderup-item-name orderup-item-details-col-large"}>
                        {item.name}
                    </div>
                    <div className={"orderup-item-name orderup-details-alignright orderup-item-details-col-tiny"}>
                        <div className={"orderup-item-padright"}>
                            {item.price}
                        </div>
                    </div>
                </div>
                <div className={"orderup-item-chip-container"}>
                    <div className={"orderup-item-details-col-large"}>
                    {
                        item.options.map((option) => {
                            return <Chip
                                key={"option" + option.id}
                                style={{marginRight: "5px"}}
                                label={option.name}
                                onClick={() => {
                                }}
                                onDelete={() => handleDeleteChip(option.id)}
                            />
                        })
                    }
                    </div>
                    <div style={{textAlign: "right"}} className={"orderup-item-details-col-tiny"}>
                        <ChevronRightOutlinedIcon/>
                    </div>
                </div>
                <div style={{marginTop: "15px", borderTop: "solid 1px #BBBBBB"}}/>
            </div>
        </React.Fragment>
    );
}