
import React from 'react';
import {Box, Button, IconButton, Modal, ThemeProvider} from "@mui/material";

import "./ContentModal.css";
import {THEME} from "../../util/ElLocaleUtil";
import CloseIcon from "@mui/icons-material/Close";

function ContentModal(props) {

    const content = props.content;
    const buttonText = props.buttonText ? props.buttonText : "Set";
    let open = props.open;
    let close = props.close;
    const width = props.width ? props.width : 300;
    const height = props.height ? props.height : 300

    const onEdit = props.edit ? props.edit : noOp;

    function closeModal() {
        if (close) {
            close();
        }
    }

    function noOp() {
        console.log("No edit function provided.");
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={props.close}>

                <div style={{
                                width: width+"px",
                                height: height+"px",
                                borderRadius: "10px",
                                borderBottom: "solid 4px",
                                borderColor: "#000000",
                                zIndex: 999
                    }}
                     className={"confirm-modal-root"}>
                    <ThemeProvider theme={THEME}>
                        <Box
                            alignItems={"center"}
                            display={"block"}
                            p={1}
                            gap={0}
                        >
                            <Box display={"inline-block"} width={"50%"}>
                                <IconButton
                                    variant={"contained"}
                                    onClick={closeModal}
                                    sx={{border: "solid 1px #BBBBBB", borderRadius: "5px", backgroundColor: "#DDDDDD", zIndex: 999}}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                            <Box display={"inline-block"} width={"50%"} textAlign={"right"}>
                                {
                                    onEdit ?
                                        <Button onClick={onEdit} variant={"contained"} color={"primary"} sx={{height: "40px", zIndex: 999}}>
                                            {buttonText}
                                        </Button> :
                                        ""
                                }
                            </Box>
                        </Box>
                    </ThemeProvider>
                    {content}
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default ContentModal;