
import React from 'react';

import "./Variation.css";
import buildVariantOption from "../../../util/Builders";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

export default function VariationModal() {

    return (
        <React.Fragment>
            <div>
                <div className={"variation-relative-header"}>Create Variation</div>
                <div className={"variation-root"}>
                    <div className={"variation-separator"}>
                        <hr/>
                    </div>
                    <div>{buildVariantOption("Variation name", "Variation name")}</div>
                    <div>{buildVariantOption("Unit cost", "$0.00/oz")}</div>
                    <div className={"variation-sales-info"}>
                        Sales Information
                    </div>
                    <div>{buildVariantOption("GTIN", "UPC, EAN, or ISBN")}</div>
                    <div>{buildVariantOption("SKU", "Unique ID")}</div>
                    <div>{buildVariantOption("Unit", "Per Ounces (.00)")}</div>
                    <div>{buildVariantOption("Price", "$0.00/oz")}</div>
                    <div>{buildVariantOption("Online sale price", "$0.00/oz")}</div>
                    <div className={"variation-dragdrop"}>
                        <span>
                            <PhotoCameraBackIcon className={"variation-photo-image"}/>Drag and drop images here, <a href={"https://ellocale.com"}>upload</a>, or <a
                            href={"https://ellocale.com"}>browse image library</a>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}