import React from 'react';
import {Component} from "react";

import "./elfooter.css";
import {Grid} from "@mui/material";

import InstagramIcon from '../../images/instagram_64.png';
import FacebookIcon from '../../images/Facebook_Icon_64x64.png';
import EmailIcon from '@mui/icons-material/Email';
import ElLocaleIcon from "../../images/ellocale_small.png";

class ElFooter extends Component {

    props = null;

    constructor(props) {
        super(props);
        this.props = props;
    }

    navigateTo(loc) {
        window.open(loc, "_blank");
    }

    render() {

        return (
            <div className={"footer-stickybottom"}>
                <div className={"footer"}>
                    <div className={"footer-pad-bottom"}>
                        <Grid container spacing={1}>
                            <Grid item md={4} sm={12} xs={12}>
                                <div className={"footer-hidden"}>
                                    <div className={"footer-title"}>Get to Know Us</div>
                                    <div className={"footer-links"}>
                                        <div>About ElLocale</div>
                                        <div>ElLocale Mission</div>
                                        <div>Locations</div>
                                        <div>Become a Seller</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <div className={"footer-title footer-ellocale-font"}>
                                    &nbsp;<img alt="ElLocale" height="32" src={ElLocaleIcon}/>
                                </div>
                                <div className={"footer-links"}>
                                    <div>
                                        <EmailIcon sx={{ color: "#000000" }} className={"footer-icon-link footer-pad-right"}
                                                   onClick={() => this.navigateTo("mailto: info@ellocale.com")}/>
                                        <img alt="Facebook" width="23" src={FacebookIcon} className={"footer-icon-link footer-pad-right"}
                                                      onClick={() => this.navigateTo("https://www.facebook.com/ElLocales")}/>
                                        <img alt="Instagram" width="23" src={InstagramIcon} className={"footer-icon-link"}
                                                       onClick={() => this.navigateTo("https://www.instagram.com/el.locale")}/>
                                    </div>
                                    <div><a href={"/documents/TermsAndConditionsEl_Locale.pdf"} target={"_blank"}  rel="noreferrer">Conditions of Use</a></div>
                                    <div>(C) 2024 ellocale.com</div>
                                </div>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <div className={"footer-hidden"}>
                                    <div className={"footer-title footer-hidden"}>My Account</div>
                                    <div className={"footer-links"}>
                                        <div>Sign Up</div>
                                        <div>Login</div>
                                        <div>My Orders</div>
                                        <div>Help</div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default ElFooter;