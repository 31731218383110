
import React from 'react';

import "./StockTracking.css";
import {MOCK_DATA_TRACKING} from "../../../util/MockData";
import {Switch} from "@mui/material";

export default function StockTrackingModal() {

    return (
        <React.Fragment>
            <div className={"stocktracking-title"}>
                Edit Stock Tracking and Alerts&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className={"stocktracking-root"}>
                {/*Header*/}
                <div style={{display: "flex"}}>
                    <div className={"stocktracking-header stocktracking-col-large stocktracking-header-left"}>Variation</div>
                    <div className={"stocktracking-header stocktracking-col-small"}>Stock</div>
                    <div className={"stocktracking-header stocktracking-col-small"}>Track</div>
                    <div className={"stocktracking-header stocktracking-col-small"}>Alert At</div>
                    <div className={"stocktracking-header stocktracking-col-small"}>Alert</div>
                </div>
                <div style={{height: "380px", overflowX: "hidden", overflowY: "auto"}}>
                {MOCK_DATA_TRACKING.map((row) => {
                    return <div style={{display: "flex"}}>
                        <div className={"stocktracking-column-standard stocktracking-column-1 stocktracking-col-large"}><div><b>{row.name}</b></div><div>{row.uid}</div></div>
                        <div className={"stocktracking-column-standard stocktracking-col-small"}>
                            <div>
                                <span className={row.quantity > row.alertAt ? "" : "stocktracking-warning"}>{row.quantity}</span>
                            </div>
                        </div>
                        <div className={"stocktracking-column-standard stocktracking-col-small"}>
                            <center><Switch checked={row.track} /></center>
                        </div>
                        <div className={"stocktracking-column-standard stocktracking-col-small"}>{row.alertAt}</div>
                        <div className={"stocktracking-column-standard stocktracking-col-small"}>
                            <center><Switch checked={row.alert}/></center>
                        </div>
                    </div>
                })}
                </div>

            </div>
        </React.Fragment>
    )
}