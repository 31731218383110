import React from 'react';

import "./BusinessToolbar.css";

import MenuIcon from '@mui/icons-material/Menu';
import ElLocalText from "../../images/ellocale_small.png";

import SearchIcon from '@mui/icons-material/Search';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


export default function BusinessToolbar(props) {

    return (
        <React.Fragment>
            <div className={"business-toolbar"}>
                <div className={"business-toolbar-navigation"}>
                    <div className={"business-toolbar-nav-grid"}>
                        <MenuIcon className={"business-toolbar-menuicon"}/>
                    </div>
                    <div className={"business-toolbar-nav-grid business-toolbar-bold"}>
                        Current Location
                    </div>
                </div>
                <div  className={"business-toolbar-title"}>
                    <img src={ElLocalText} height={30}/>
                </div>
                <div className={"business-toolbar-options"}>
                    <div className={"business-toolbar-nav-grid"}>
                        <div style={{marginTop: "3px"}}>
                            <SearchIcon className={"business-toolbar-image-pad"}/>
                            <ForumOutlinedIcon className={"business-toolbar-image-pad"}/>
                            <NotificationsActiveOutlinedIcon className={"business-toolbar-image-pad"}/>
                            <HelpOutlineOutlinedIcon className={"business-toolbar-image-pad"}/>
                        </div>
                    </div>
                    <div
                        className={"business-toolbar-nav-grid " +
                        "business-toolbar-image-pad " +
                        "business-toolbar-business " +
                        "business-toolbar-bold"}>
                        Business
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}