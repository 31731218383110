import React, {useState} from 'react';

import "./Identity.css";

import ElLocaleText from "../../images/ellocale_small.png";
import {Checkbox, FormGroup, MenuItem, Paper, TextField} from "@mui/material";
import US_STATES from "../../util/USStates";

export default function Identity(props) {

    const companyName = props.companyName ? props.companyName : "Unknown Company";
    const [addressesSame, setAddressesSame] = useState(false);

    const handleSameAddressCheckbox = (obj) => {
        let value = !addressesSame;
        setAddressesSame(value);
    }

    console.log(addressesSame);

    return (
        <React.Fragment>
            <div style={{marginBottom: "30px"}}>
                <img src={ElLocaleText} alt={"El Locale Text"} height={40}/>
            </div>
            <div className={"identify-container"}>
                <div className={"identity-title identity-bold"}>
                    Verify your identity
                </div>
                <div className={"identity-description"}>
                    Before you can start taking payments, we'll need to verify a few pieces of personal information.
                    Check out our <a href={"https://ellocale.com"}>Privacy Policy</a> for more details on how
                    we protect your information.
                </div>
                <div className={"identity-form-container"}>
                    <FormGroup>
                        <div style={{marginTop: "0px"}}>
                            <TextField
                                className={"identity-form-item"}
                                label={"First legal name"}
                                variant={"outlined"}
                                placeholder={"First legal name"}/>
                        </div>
                        <div className={"identity-form-div"}>
                            <TextField
                                className={"identity-form-item"}
                                label={"Last legal name"}
                                variant={"outlined"}
                                placeholder={"Last legal name"}/>
                        </div>
                        <div className={"identity-form-div"}>
                            <TextField
                                className={"identity-form-item"}
                                label={"Phone number"}
                                variant={"outlined"}
                                placeholder={"(XXX)-XXX-XXXX"}/>
                        </div>
                        <div style={{marginTop: "40px"}}>
                            <TextField
                                className={"identity-form-item"}
                                label={"Date of birth"}
                                variant={"outlined"}
                                placeholder={"MM/DD/YYYY"}/>
                        </div>
                        <div className={""}>>
                            <TextField
                                className={"identity-form-item"}
                                label={"Last 4 digits of SSN or ITIN"}
                                variant={"outlined"}
                                placeholder={"1234"}/>
                        </div>
                        <div style={{marginTop: "40px", display: "flex"}}>
                            <div style={{flexGrow: "0.1"}}>
                                <Checkbox onChange={handleSameAddressCheckbox} value={addressesSame}/>
                            </div>
                            <div style={{
                                flexGrow: "20.0",
                                textAlign: "left",
                                fontSize: "12px",
                                display: "grid",
                                alignContent: "center",
                            }} className={"identity-bold"}>
                                My home address and business address are the same
                            </div>
                        </div>
                        <div style={{marginTop: "20px", textAlign: "left", marginLeft: "5px"}}
                             className={"identity-bold"}>
                            Business Address
                        </div>
                        <Paper style={{marginTop: "10px"}} elevation={10}>
                            <div className={"identity-form-div"}>
                                <TextField
                                    className={"identity-form-item-constrained"}
                                    label={"Street Address or PO Box"}
                                    variant={"outlined"}
                                    placeholder={"123 First Street"}/>
                            </div>
                            <div className={"identity-form-div"}>
                                <TextField
                                    className={"identity-form-item-constrained"}
                                    label={"Apartment #, Suite, etc... (optional)"}
                                    variant={"outlined"}
                                    placeholder={"Unit 123"}/>
                            </div>
                            <div className={"identity-form-div"}>
                                <TextField
                                    className={"identity-form-item-constrained"}
                                    label={"City"}
                                    variant={"outlined"}
                                    placeholder={"Portsmouth"}/>
                            </div>
                            <div style={{display: "flex"}} className={"identity-form-div"}>
                                <TextField
                                    style={{flexGrow: "2.0", marginRight: "5px", marginLeft: "30px"}}
                                    select
                                    className={"identity-form-item-constrained"}
                                    label={"State"}
                                    variant={"outlined"}
                                    placeholder={"New Hampshire"}>
                                    {
                                        US_STATES.map(state => {
                                            return <MenuItem key={state.abbreviation}>
                                                {state.name}
                                            </MenuItem>
                                        })
                                    }
                                </TextField>
                                <TextField
                                    style={{
                                        flexGrow: "1.0",
                                        marginLeft: "5px",
                                        marginRight: "30px",
                                        marginBottom: "20px"
                                    }}
                                    className={"identity-form-item-constrained"}
                                    label={"Zip Code"}
                                    variant={"outlined"}
                                    placeholder={"01234"}/>
                            </div>
                        </Paper>


                        <div style={{display: addressesSame ? "none" : "unset"}}>
                            <div style={{marginTop: "30px", textAlign: "left", marginLeft: "5px"}}
                                 className={"identity-bold"}>
                                Home Address
                            </div>
                            <Paper style={{marginTop: "-10px"}} elevation={10}>
                                <div style={{paddingTop: "20px"}} className={"identity-form-div"}>
                                    <TextField
                                        className={"identity-form-item-constrained"}
                                        label={"Street Address or PO Box"}
                                        variant={"outlined"}
                                        placeholder={"123 First Street"}/>
                                </div>
                                <div className={"identity-form-div"}>
                                    <TextField
                                        className={"identity-form-item-constrained"}
                                        label={"Apartment #, Suite, etc... (optional)"}
                                        variant={"outlined"}
                                        placeholder={"Unit 123"}/>
                                </div>
                                <div className={"identity-form-div"}>
                                    <TextField
                                        className={"identity-form-item-constrained"}
                                        label={"City"}
                                        variant={"outlined"}
                                        placeholder={"Portsmouth"}/>
                                </div>
                                <div style={{display: "flex"}} className={"identity-form-div"}>
                                    <TextField
                                        style={{flexGrow: "2.0", marginRight: "5px", marginLeft: "30px"}}
                                        select
                                        className={"identity-form-item-constrained"}
                                        label={"State"}
                                        variant={"outlined"}
                                        placeholder={"New Hampshire"}>
                                        {
                                            US_STATES.map(state => {
                                                return <MenuItem key={state.abbreviation}>
                                                    {state.name}
                                                </MenuItem>
                                            })
                                        }
                                    </TextField>
                                    <TextField
                                        style={{
                                            flexGrow: "1.0",
                                            marginLeft: "5px",
                                            marginRight: "30px",
                                            marginBottom: "20px"
                                        }}
                                        className={"identity-form-item-constrained"}
                                        label={"Zip Code"}
                                        variant={"outlined"}
                                        placeholder={"01234"}/>
                                </div>
                            </Paper>
                        </div>

                        <div style={{marginTop: "40px", display: "flex"}}>
                            <div style={{flexGrow: "0.1"}}>
                                <Checkbox/>
                            </div>
                            <div style={{
                                flexGrow: "20.0",
                                textAlign: "left",
                                fontSize: "12px",
                                display: "grid",
                                alignContent: "center",
                            }} className={"identity-bold"}>
                                By checking this box, you certify that you are authorized to create
                                an account on behalf of {companyName}
                            </div>
                        </div>

                    </FormGroup>
                </div>
            </div>
        </React.Fragment>
    );
}