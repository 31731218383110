
import React from 'react';

import "./Ownership.css";
import {Checkbox, FormControl, MenuItem, TextField} from "@mui/material";
import ElLocaleText from "../../images/ellocale_small.png";

export default function Ownership(props) {

    const company = props.company ? props.company : "Unknown Company"

    return (
        <React.Fragment>
            <div style={{marginBottom: "30px"}}>
                <img src={ElLocaleText} alt={"El Locale Text"} height={40}/>
            </div>
            <div className={"ownership-container"}>
                <div className={"ownership-question ownership-bold"}>
                    Who owns and directs your business?
                </div>
                <div className={"ownership-description"}>
                    We are oblicated to collect information of the beneficial owners of your business.
                    Please tell us about (i) all business owners who directly or indirectly own 25% or more
                    of the business, and (ii) an executive manager with significant responsibility to
                    manage, direct, or control the business.
                    <span className={"ownership-bold"}
                          style={{color: "royalblue", marginLeft: "20px"}}>Learn More</span>
                </div>
                <div className={"ownership-role-container"}>
                    <div style={{textAlign: "left", marginLeft: "10px"}} className={"ownership-bold"}>
                        Your Role
                    </div>
                    <div style={{textAlign: "center", width: "100%"}}>
                        <FormControl style={{width: "99%"}}>
                            <TextField select variant={"outlined"}
                                       className={"ownership-combo"}
                                       defaultValue={"em"}
                                       placeholder={"em"}>
                                <MenuItem key={"em"} value={"em"}>Executive Manager</MenuItem>
                                <MenuItem key={"own"} value={"own"}>Business Owner</MenuItem>
                            </TextField>
                        </FormControl>
                    </div>
                    <div className={"ownership-additional-beneficials"}>
                        <div className={"ownership-bold"}>
                            Additional beneficial owners
                        </div>
                        <div className={"ownership-bold ownership-beneficiaries"} style={{color: "royalblue"}}>
                            + Add a beneficial owner
                        </div>
                    </div>
                    <div style={{display: "flex", marginTop: "30px"}}>
                        <div style={{flexGrow: "0.1"}}>
                            <Checkbox/>
                        </div>
                        <div style={{flexGrow: "20.0", textAlign: "left", fontSize: "12px"}}>
                            By checking this box, you certify that the information you provided regarding
                            the beneficial owners of "{company}" is complete and accurate. You agree that
                            El Locale may use this information in accordance with our &nbsp;
                            <a href={"https://ellocale.com"}>Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
