
import React from 'react';

import "./AddEditAddress.css";
import {Button, TextField, ThemeProvider} from "@mui/material";
import US_STATES from "../../util/USStates";
import MenuItem from "@mui/material/MenuItem";

import {THEME} from "../../util/ElLocaleUtil";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

function AddEditAddress(props) {

    const mode = props.mode ? props.mode : "Add";
    const type = props.type ? props.type : "Shipping";

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"addeditaddress-root"}>
                <div className={"addeditaddress-title"}>
                    {mode} a {type} Address
                </div>
                <div className={"addeditaddress-columncontainer"}>
                    <div className={"addeditaddress-columnitem"}>
                        <TextField
                            style={{width: "100%"}}
                            label="First Name"
                            defaultValue=""
                        />
                    </div>
                    <div className={"addeditaddress-columnitem"}>
                        <TextField
                            style={{width: "100%"}}
                            label="Last Name"
                            defaultValue=""
                        />
                    </div>
                </div>
                <div className={"addeditaddress-columnitem"}>
                    <TextField
                        style={{width: "100%"}}
                        label="Street Address"
                        defaultValue=""
                    />
                </div>
                <div className={"addeditaddress-columnitem addeditaddress-extra-margin-top"}>
                    <TextField
                        style={{width: "100%"}}
                        label="Apt, Suite, Floor, etc..."
                        defaultValue=""
                    />
                </div>
                <div className={"addeditaddress-columnitem addeditaddress-extra-margin-top"}>
                    <TextField
                        style={{width: "100%"}}
                        label="City"
                        defaultValue=""
                    />
                </div>
                <div className={"addeditaddress-columncontainer"}>
                    <div className={"addeditaddress-columnitem"}>
                        <TextField
                            select
                            label="State"
                            defaultValue="New Hampshire"
                            style={{width: "100%", textAlign: "left"}}
                        >
                            {US_STATES.map((option) => (
                                <MenuItem key={option.abbreviation} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className={"addeditaddress-columnitem"}>
                        <TextField
                            style={{width: "100%"}}
                            label="Zip Code"
                            defaultValue=""
                        />
                    </div>
                </div>
                <div className={type !== "Shipping" ? "addeditaddress-hidden" : ""}>
                    <div className={"addeditaddress-columnitem"}>
                        <TextField
                            style={{width: "100%"}}
                            label="Phone Number (Used to assist with delivery)"
                            defaultValue=""
                        />
                    </div>
                    <div className={"addeditaddress-columnitem addeditaddress-extra-margin-top"}>
                        <TextField
                            style={{width: "100%"}}
                            label="Special Delivery Instructions"
                            defaultValue=""
                        />
                    </div>
                </div>
                <div className={"addeditaddress-columncontainer-right"}>
                    <div className={"addeditaddress-extra-margin-righttop"}>
                        <ThemeProvider theme={THEME}>
                            <span>
                                <Button style={{fontSize: "12px", height: "37px", fontWeight: "700", width: "100px"}}
                                        size={"small"} variant={"contained"} color="grayed">
                                    <span>Cancel</span>
                                </Button>
                            </span>
                            <span className={"addeditaddress-marginleft"}>
                                <Button style={{fontSize: "12px", height: "37px", fontWeight: "700", width: "100px"}}
                                        size={"small"} variant={"contained"} color="primary">
                                    <span>Save</span>
                                </Button>
                            </span>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    )
}

export default AddEditAddress;