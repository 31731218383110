import React, {useState} from 'react';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ElLocalIcon from "../../images/ellocale_small.png";
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';

// menu imports
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';


import "./elheader.css";
import {AppBar, Grid, IconButton, InputAdornment, Menu, TextField, Toolbar} from "@mui/material";

function ElHeader(props) {

    const [anchorElement, setAnchorElement] = useState(null);
    const sidemenus = props.sidemenus ? props.sidemenus : [];

    function handleCloseMenu(event) {
        setAnchorElement(null);
    }

    function handleOpenMenu(event) {
        setAnchorElement(event.currentTarget);
    }

    function createMenuItems() {

        let menuItems = [];
        if (sidemenus.length > 0) {
            sidemenus.map((menu, idx) => {

                menuItems.push(<MenuItem key={menu.name+"_root"} className={menu.cssshow}><b>{menu.name}</b></MenuItem>);

                for (let i = 0; i < menu.list.length; i++) {
                    menuItems.push(<MenuItem key={menu.name+"_"+i} className={menu.cssshow} sx={{pt: 0, pb: 0, mt: 0, mb: 0}}><span className={"header-padleft"}>{menu.list[i].description}</span></MenuItem>)
                }

                if (idx < sidemenus.length-1) {
                    menuItems.push(<Divider key={menu.name+"_divider"} className={menu.cssshow}/>);
                }

                return true;
            });
        }

        return menuItems;
    }

    return (
        <div>
            <div className={"header-full"}>
                <Grid container alignContent={"center"} textAlign={"center"}>
                    <Grid item md={3} sm={3} xs={12}>
                        <span className={"header-elloc header-float-left"}>
                            <img alt="El Locale" className={"header-elloc-image"} src={ElLocalIcon}/>
                        </span>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <span>
                            <input className={"search header-roundedcorners"} type={"text"}
                                     placeholder={"  Search by business or product or locality"}/>
                        </span>
                    </Grid>
                    <Grid item md={3} sm={3} xs={12}>
                        <div className={"header-float-right"}>
                        <ShoppingCartIcon className={"header-margin-top"}/>
                        <span className={"header-other header-minus-nine header-small-font"}>Need Help?</span>
                        <span className={"header-other header-minus-eight header-farright header-bold header-padright"}>SIGN IN</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={"header-responsive"}>
                    <AppBar position={"static"}>
                        <Toolbar sx={{justifyContent: "space-between", backgroundColor: "#000000"}}>
                            <div>
                                <IconButton onClick={handleOpenMenu} edge="end" color="inherit" aria-label="menu">
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorElement}
                                    open={anchorElement != null}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <MenuList sx={{pt: 0, pb: 0}} dense>
                                    {
                                        createMenuItems()
                                    }
                                    </MenuList>
                                    {/*<MenuItem onClick={handleCloseMenu}>Profile</MenuItem>*/}
                                    {/*<MenuItem><span className={"header-padleft"}>My account</span></MenuItem>*/}
                                    {/*<MenuItem >Logout</MenuItem>*/}
                                </Menu>
                            </div>
                            <div/>
                            <div>
                                <span style={{paddingRight: "15px"}}>
                                    <TextField
                                        size={"small"}
                                        style={{maxWidth: "205px"}}
                                        variant="outlined"
                                        placeholder={"search"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon sx={{color: "#CCC"}} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            // Root class for the input field
                                            "& .MuiOutlinedInput-root": {
                                                color: "#FFF",
                                                fontFamily: "Arial",
                                                fontWeight: "bold",
                                                // Class for the border around the input field
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#FFF",
                                                    borderWidth: "2px",
                                                },
                                            },
                                            // Class for the label of the input field
                                            "& .MuiInputLabel-outlined": {
                                                color: "#CCC",
                                                fontWeight: "bold",
                                            },
                                        }}
                                    />
                                </span>
                                <IconButton edge="end" color="inherit" aria-label="menu" sx={{mr:1}}>
                                    <ShoppingCartIcon/>
                                </IconButton>
                                <IconButton edge="end" color="inherit" aria-label="menu">
                                    <PersonOutlineIcon/>
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
            </div>
        </div>
    );
}

export default ElHeader;