
import React from 'react';
import NoImage from "../../images/noimage.png";
import DeleteIcon from '@mui/icons-material/Delete';

import "./sellable.css";

function Sellable(props) {

    let image = props.image ? props.image : NoImage;

    return (
        <React.Fragment>
            <div className={"sellable-div sellable-margin-bottom"}>
                <div className={"sellable-img-min-width"}>
                    <img alt="Product" className={"sellable-image"} src={image}/>
                </div>
                <div className={"sellable-description-pane"}>
                    <div className={"sellable-small-font sellable-font-bold"}>
                        Romance Tea
                    </div>
                    <div className={"sellable-small-font sellable-pad-top"}>
                        Variation: Jar
                    </div>
                    <div className={"sellable-button-div"}>
                        <button className={"sellable-button-pad sellable-button"}>-</button>
                        <span className={"sellable-button-pad"}>2</span>
                        <button className={"sellable-button-pad sellable-button"}>+</button>
                    </div>
                </div>
                <div className={"sellable-align-right sellable-small-font sellable-font-bold"}>
                    <div>$26.00</div>
                    <div className={"sellable-margin-large"}>
                        <DeleteIcon fontSize={"small"}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sellable;