
import React from 'react';

import "./LoginSecurity.css";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import EditRow from "../../components/edit/EditRow";

export default function LoginSecurity(props) {

    if (!props) {
        console.log("No properties passed in.");
    }

    const name = props.name ? props.name : "No name specified";
    const email = props.email ? props.email : "No email specified";
    const phone = props.phone ? props.phone : "No phone specified";

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"loginsecurity-root"}>
                <div className={"loginsecurity-header"}>
                    Login & Security
                </div>
                <div className={"loginsecurity-editcontainer"}>
                    <div className={"loginsecurity-div"}>
                        <EditRow title={"Name"} value={name} button={"edit"}/>
                    </div>
                    <div className={"loginsecurity-div loginsecurity-topborder"}>
                        <EditRow title={"Email"} value={email} button={"edit"}/>
                    </div>
                    <div className={"loginsecurity-div loginsecurity-topborder"}>
                        <EditRow title={"Primary Mobile Number"} value={phone} button={"edit"}
                                 desc={"Quickly sign-in, easily recover passwords, and receive security notifications with this mobile number"}/>
                    </div>
                </div>
                <div className={"loginsecurity-editcontainer loginsecurity-largemargin-top"}>
                    <div className={"loginsecurity-div"}>
                        <EditRow title={"Password"} value={"*********"} button={"edit"}/>
                    </div>
                    <div className={"loginsecurity-div loginsecurity-topborder"}>
                        <EditRow title={"2-Step Verification"} value={phone} button={"manage"}
                                desc={"Enter a code sent to your verification method, in addition to your password to sign in securely."}/>
                    </div>
                </div>
            </div>
            <ElFooter/>
        </React.Fragment>
    );
}