
import React from 'react';
import "./Subscription.css";

import ElLocaleText from "../../images/ellocale_small.png";
import {FormControlLabel, Paper, Radio, RadioGroup, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";


export default function Subscription(props) {

    const subscriptions = props.subscriptions ? props.subscriptions : [
        {
            type: "Monthly",
            amount: "$100"
        } ,
        {
            type: "Annually",
            amount: "$999"
        } ,
    ];


    return (
        <React.Fragment>
            <div className={"subscription-container"}>
                <div>
                    <img alt={"ElLocale Text"} src={ElLocaleText} height={40}/>
                </div>
                <div style={{maxWidth: "400px", margin: "auto"}}>
                    <div style={{fontSize: "26px", fontWeight: "700", marginTop: "50px"}}>Choose a subscription</div>
                    <Paper elevation={10} style={{padding: "30px", marginTop: "10px", borderRadius: "15px", display: "flex"}}>
                        <div style={{width: "100%"}}>
                            <ThemeProvider theme={THEME}>
                                <RadioGroup defaultValue={"Monthly"}>
                                    {
                                        subscriptions.map((sub, idx) => {
                                            return <div style={{textAlign: "left", display: "flex"}}>
                                                <FormControlLabel style={{paddingRight: "20px", flexGrow: "1.0"}} className={"subscription-font"} value={sub.type} control={<Radio/>} label={sub.type}/>
                                                <div style={{textAlign: "right", flexGrow: "10.0", display: "grid", alignContent: "center"}}>{sub.amount}</div>
                                            </div>
                                        })
                                    }
                                </RadioGroup>
                            </ThemeProvider>
                        </div>
                    </Paper>
                </div>
            </div>
        </React.Fragment>
    )
}