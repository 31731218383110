
import React from 'react';

import "./IndividualProduct.css";
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from "@mui/material/MenuItem";

function IndividualProduct(props) {

    let options = props.options ? props.options : null;
    let image = props.image ? props.image : null;
    let name = props.name ? props.name : "No Name Available";
    let desc = props.desc ? props.desc : null;
    let price = props.price ? props.price : 0.0;
    let category = props.category ? props.category : null;
    let subcategory = props.subcategory ? props.subcategory : null;

    let title = <span><b>{category}</b> &gt; {subcategory}</span>;

    return (
        <React.Fragment>
            <ElHeader/>
            <div className={"individual-product-root"}>
                <div className={"individual-product-location"}>
                    {(category && subcategory) ? title : ""}
                </div>
                <div className={"individual-product-columns"}>
                    <div className={"individual-product-col1"}>
                        <div className={"individual-product-image-div"} style={{height: "100%"}}>
                            <img className={"individual-product-image"} src={image} alt={"Product"}/>
                        </div>
                    </div>
                    <div className={"individual-product-col2"}>
                        <div className={"individual-product-name"}>
                            {name}
                        </div>
                        <div className={"individual-product-price"}>
                            {price}
                        </div>
                        <div className={"individual-product-button-div"}>
                            <button className={"individual-product-button-pad individual-product-button"}>-</button>
                            <span className={"individual-product-button-pad individual-product-quantity"}>2</span>
                            <button className={"individual-product-button-pad individual-product-button"}>+</button>
                        </div>

                        {/* BEGIN Option Pane */}
                        { options ?
                            <div className={"individual-product-options"}>
                                <div className={"individual-product-option-title"}>
                                    <span>{options.title}</span>
                                    <span className={"individual-product-required"}>Required</span>
                                </div>
                                <div className={""}>
                                <TextField
                                    id="product-option"
                                    select
                                    size="small"
                                    defaultValue={options.itemOptions[0].text}
                                    className={"individual-product-option-select"}
                                    sx={{
                                        '.MuiInputBase-input': {fontSize: '12px', height: "23px"},
                                        ".MuiInputLabel-root": {fontFamily: "Montserrat", fontSize: "12px"},
                                        ".MuiInputLabel-formControl": {fontSize: "12px", paddingTop: "4px"}
                                    }}
                                >
                                {options.itemOptions.map((option) => (
                                    <MenuItem key={option.id} value={option.text}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                                </TextField></div>
                            </div> : ""
                        }
                        {/* END Option Pane*/}
                        <div className={"individual-product-options"}>
                            <div className={"individual-product-option-title"}>How to get it</div>
                            <div style={{width: "100%"}}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="individual-product-shipping"
                                        defaultValue="Shipping"
                                        name="individual-product-shipping-options"
                                    >

                                        <FormControlLabel
                                              value="Shipping"
                                              control={<Radio size={"tiny"}/>}
                                              label={<span className={"individual-product-option-font"}>Shipping</span>}
                                        />

                                        <hr style={{position: "relative", top: "-10px", borderTop: "solid 1px", width: "100%", borderColor: "lightgray"}}/>

                                        <span style={{position: "relative", top: "-20px"}}>
                                        <FormControlLabel
                                            value="Store Pickup"
                                            control={<Radio size={"tiny"}/>}
                                            label={<span className={"individual-product-option-font"}>Store Pickup</span>}
                                        />
                                        </span>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        <div>
                            <button className={"individual-product-payment-button individual-product-button-text"}>Add to Cart&nbsp;&nbsp;&nbsp;{price}</button>
                        </div>
                            <div className={"individual-product-options"}>
                                <Accordion sx={{'.MuiButtonBase-root': {minHeight: "40px", maxHeight: "40px"}}} defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <span className={"individual-product-option-title"}>Description</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <span className={"individual-product-desc-font"}>{desc}</span>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                    </div>
                </div>
            </div>
        </div>
            <div  className={"individual-product-footer"}>
            <ElFooter/>
            </div>
        </React.Fragment>
    );
}

export default IndividualProduct;