
import React, {useState} from 'react';

import "./DialogTestChooser.css";

import ContentModal from "../../components/modals/ContentModal";
import {Button, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";
import ItemCategoryDialog from "../../bundles/inventory/ItemCategoryDialog";

function DialogTestChooser(props) {

    if (!props) {
        console.log("No properties passed");
    }

    const defaultContent = <React.Fragment>
        There's no content loaded yet...
    </React.Fragment>

    const [dialog, setDialog] = useState({
        open: false,
        width: window.innerWidth > 500 ? 500 : (window.innerWidth - 20),
        height: 600,
        content: defaultContent
    });

    function openDialog(dialog) {

        switch (dialog) {
            case "edit-category":
                openEditCategory();
                return;
            default:
                return;
        }
    }

    function openEditCategory() {

        setDialog({
            open: true,
            width: dialog.width,
            height: dialog.height,
            content: <ItemCategoryDialog close={handleClose} action={handleClose}/>
        });
    }

    function handleClose() {

        setDialog({
            open: false,
            width: dialog.width,
            height: dialog.height,
            content: dialog.content
        });
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
            <div className={"dialog-test-chooser-root"}>
                <div>
                    <Button onClick={() => openDialog("edit-category")} style={{fontSize: "12px", height: "37px", fontWeight: "700", width: "150px"}}
                            size={"small"} variant={"contained"}>
                        <span>Edit Item Categories</span>
                    </Button>
                </div>
            </div>
            </ThemeProvider>

            <ContentModal
                open={dialog.open}
                close={handleClose}
                width={dialog.width}
                height={dialog.height}
                content={dialog.content}/>
        </React.Fragment>
    )
}

export default DialogTestChooser;