
import React, {useState} from 'react';
import "./ActivateAccount.css";
import {Button, Step, StepLabel, Stepper, ThemeProvider} from "@mui/material";
import Ownership from "../components/Ownership";
import Identity from "../components/Identity";
import BankAccount from "../components/BankAccount";
import Subscription from "../components/Subscription";
import PaymentMethod from "../components/PaymentMethod";
import {THEME} from "../../util/ElLocaleUtil";

/**
 * This component is intended to be a container with a stepper to display and
 * keep track of state and stage
 *
 * @param props
 * @constructor
 */
export default function ActivateAccount(props) {

    const [activeStep, setActiveStep]  = useState(0);
    const steps = [
        {
            label: "Ownership",
            index: 0,
            component: <Ownership/>
        },
        {
            label: "Verify",
            index: 1,
            component: <Identity/>
        },
        {
            label: "Bank Account",
            index: 2,
            component: <BankAccount/>
        },
        {
            label: "Choose Subscription",
            index: 3,
            component: <div><Subscription/><PaymentMethod/></div>
        }
    ];

    const advanceStep = () => {
        let step = activeStep+1;

        if (step < steps.length) {
            setActiveStep(step);
        }
    }

    const reverseStep = () => {
        let step = activeStep - 1;

        if (step >= 0){
            setActiveStep(step);
        }
    }

    return (
        <React.Fragment>
            <div className={"activateaccount-container"}>
                <Stepper activeStep={activeStep}>
                    {
                        steps.map(step => {
                            return (
                                <Step key={step.label}>
                                    <StepLabel>{step.label}</StepLabel>
                                </Step>
                            );
                        })
                    }
                </Stepper>

                <div className={"activateaccount-component-container"}>
                    {steps[activeStep].component}
                </div>

                <ThemeProvider theme={THEME}>
                    <div className={"activateaccount-button-container"}>
                        <Button style={{minWidth: "150px", marginRight: "10px"}} variant={"contained"} onClick={reverseStep}>
                            {(activeStep === 0) ? "Do This Later" : "Back"}
                        </Button>
                        <Button style={{minWidth: "150px", marginLeft: "10px"}} variant={"contained"} onClick={advanceStep}>
                            {activeStep === (steps.length - 1) ? "Finish" : "Continue"}
                        </Button>
                    </div>
                </ThemeProvider>
            </div>
        </React.Fragment>
    );

}