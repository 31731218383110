
import React from 'react';

import "./ManageStock.css";
import {MOCK_DATA_TRACKING} from "../../../util/MockData";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function ManageStockModal() {

    return (
        <React.Fragment>
            <div className={"managestock-title"}>
                Manage Stock&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className={"managestock-root"}>
                {/*Header*/}
                <div style={{display: "flex"}}>
                    <div className={"managestock-header managestock-col-large"}>Variation</div>
                    <div className={"managestock-header managestock-col-tiny"}>Stock</div>
                    <div className={"managestock-header managestock-col-medium"}>Reason</div>
                    <div className={"managestock-header managestock-col-tiny"}>Adj</div>
                    <div className={"managestock-header managestock-col-tiny"}>#</div>
                </div>
                <div style={{height: "380px", overflowX: "hidden", overflowY: "auto"}}>
                    {MOCK_DATA_TRACKING.map((row) => {
                        return <div style={{display: "flex"}}>
                            <div className={"managestock-column-standard managestock-column-1 managestock-col-large"}><div><b>{row.name}</b></div><div>{row.uid}</div></div>
                            <div className={"managestock-column-standard managestock-col-tiny"}>
                                <div>
                                    <span>{row.quantity}</span>
                                </div>
                            </div>
                            <div className={"managestock-column-standard managestock-col-medium"} style={{display: "flex"}}>
                                <TextField
                                    select
                                    defaultValue={0}
                                    variant={"outlined"}
                                    style={{flexGrow: 10.0}}
                                    size={"small"}
                                >
                                    <MenuItem value={0}><span className={"managestock-font"}>select reason</span></MenuItem>
                                    <MenuItem value={1}><span className={"managestock-font"}>Lost/Damaged</span></MenuItem>
                                    <MenuItem value={2}><span className={"managestock-font"}>Replacement Item</span></MenuItem>
                                </TextField>
                            </div>
                            <div className={"managestock-column-standard managestock-col-tiny"}>{row.alertAt}</div>
                            <div className={"managestock-column-standard managestock-col-tiny"}>
                                0
                            </div>
                        </div>
                    })}
                </div>

            </div>
        </React.Fragment>
    )
}