
import React from 'react';

import "./EditRow.css";
import {Button, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";

export default function EditRow(props) {

    const TITLE = props.title ? props.title : "No title specified";
    const VALUE = props.value ? props.value : "No value specified";
    const DESC = props.desc ? props.desc : null;
    const BUTTON = props.button ? props.button : "action";

    if (!props) {
        console.log("No properties specified");
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={THEME}>
            <div className={"editrow-root"}>
                <div className={"editrow-leftcolumn"}>
                    <div className={"editrow-font"} style={{fontWeight: 700}}>{TITLE}</div>
                    <div className={"editrow-font"}>{VALUE}</div>
                    {DESC ? <div className={"editrow-font"}>{DESC}</div> : ""}
                </div>
                <div className={"editrow-rightcolumn"}>
                    <Button style={{minWidth: "100px"}} onClick={() => {}} size={"small"} variant={"contained"} color="primary">
                        <div className={"editrow-button-font"}>{BUTTON}</div>
                    </Button>
                </div>
            </div>
            </ThemeProvider>
        </React.Fragment>
    );
}