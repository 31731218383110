
import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";

import { THEME, doFetch } from "../../util/ElLocaleUtil";
import "./interest.css";

import InterestForm from "./interest_form";
import InterestComplete from "./interest_complete";
import InterestValidated from "./interest_validated";

class ELInterest extends React.Component {

    props = null;
    validating = false;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            complete: false,
            validated: false
        };

        this.checkForCompletion();
    }

    checkForCompletion() {
        const queryParameters = new URLSearchParams(window.location.search)

        if (queryParameters.has("interestId") && !this.state.validating) {
            const interestId = queryParameters.get("interestId");

            if (!this.validating && !this.state.validated) {
                this.completeInterest(interestId);
            }
        }
    }

    handleValidationResponse(response) {
        if (response.status === "success") {
            this.setState({
                validated: true,
                complete: true
            });
        }
    }

    clearForm() {
        this.setState({
           complete: false,
           validated: false
        });

        window.location = window.location.pathname;
    }

    completeInterest(interestId) {

        this.validating = true;

        let payload = {
            interestId : interestId
        };

        doFetch("interest/validate", payload, (response) => this.handleValidationResponse(response));
    }

    setComplete(val) {
        this.setState({
            complete: val
        });
    }

    setValidated(val) {
        this.setState( {
            validated: true
        });

        this.props.parent.setState({
            validated: true
        });
    }

    render() {

        return  <React.Fragment>
                    <CssBaseline/>
                    <ThemeProvider theme={THEME}>
                        <div className={"interest-container"}>
                            {
                                this.state.complete === false ? <InterestForm parent={this}/> :
                                    this.state.validated ? <InterestValidated/> : <InterestComplete/>
                            }
                        </div>
                    </ThemeProvider>
                </React.Fragment>
    }
}

export default ELInterest;