
import React from 'react';

import "./OrderConfirmationHeader.css";

import WhatshotIcon from '@mui/icons-material/Whatshot';

function OrderConfirmationHeader(props) {

    let shippingAddress = props.shipping ? props.shipping : "Pick Up";
    let savings = props.savings ? props.savings : "$0.00";
    let orderNumber = props.ordernum ? props.ordernum : "Not Available";

    return (
        <React.Fragment>
            <div className={"orderconfirmation-root"}>
                <div className={"orderconfirmation-ordernum-div"}>
                    <span className={"orderconfirmation-ordernumber"}>Order #: {orderNumber}</span>
                </div>
                <div>
                    <span className={"orderconfirmation-orderplaced-span"}>Yay!  Your order has been placed</span> <span className={"orderconfirmation-hoticon"}><WhatshotIcon/></span>
                </div>
                <div>
                    <span className={"orderconfirmation-conformation-text"}>Confirmation has been sent to your email.</span>
                </div>
                <div className={"orderconfirmation-shipping"}>
                    <span className={"orderconfirmation-shippingto-span"}>Shipping to: </span><span>{shippingAddress}</span>
                </div>
                <div className={"orderconfirmation-hr"}>
                    <hr/>
                </div>
                <div className={"orderconfirmation-savings"}>
                    You saved {savings} in shipping fees because you're a locale!
                </div>
            </div>
        </React.Fragment>
    );
}

export default OrderConfirmationHeader;