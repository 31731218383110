
import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import "./AddressDetailCard.css";

import ElLocaleSmall from "../../images/ellocale_small.png";

function AddressDetailCard(props) {

    const sizeX = props.sizeX ? props.sizeX : 260;
    const sizeY = props.sizeY ? props.sizeY : 200;

    const isButton = props.isButton ? props.isButton : false;
    const shippingDetails = props.details ? props.details : {
        shipTo: "No Data",
        addressLine: "123 Sample Street",
        cityLine: "Portsmouth, NH 03801-3727",
        country: "United States",
        phoneNum: "603-420-9117",
        isDefault: true
    };

    const setDefault = <a href={"https://ellocale.com"}>Set Default</a>

    const button =
            <React.Fragment>
                <div className={"shippingdetails-card-button shippingdetails-card-button-font"}>
                    <div>
                        <AddIcon fontSize={"large"}/>
                    </div>
                    <div className={"shippingdetails-darker-font"}>
                        Add Address
                    </div>
                </div>
            </React.Fragment>

    const card =
        <React.Fragment>
            <div className={"shippingdetails-card-address"}>
                <div style={{marginLeft: "10px"}}>
                    <div style={{display: shippingDetails.isDefault ? "inline-block" : "none"}} className={"shippingdetails-card-default-div"}>
                        <span>Default: </span>
                        <span className={"shippingdetails-card-image-span"}>
                            <img className={"shippingdetails-card-default-image"} height="25" src={ElLocaleSmall}
                                 alt={"El Locale Small"}/>
                        </span>
                        <hr style={{border: "dotted 1px #DDDDDD"}}/>
                    </div>
                    <div className={shippingDetails.isDefault ? "shippingdetails-card-font-bold"
                        : "shippingdetails-card-font-bold shippingdetails-card-margin-top"}>
                        {shippingDetails.shipTo}
                    </div>
                    <div>
                        {shippingDetails.addressLine}
                    </div>
                    <div>
                        {shippingDetails.cityLine}
                    </div>
                    <div>
                        {shippingDetails.country}
                    </div>
                    <div>
                        Phone Number: {shippingDetails.phoneNum}
                    </div>
                    <div>
                        <a href={"https://ellocale.com"}>Edit Delivery Preferences</a>
                    </div>
                    <div className={"shippingdetails-card-flex-full"}>
                        <a href={"https://ellocale.com"}>Edit</a> | <a href={"https://ellocale.com"}>Remove</a>
                        {!shippingDetails.isDefault ? " | " : ""}
                        {!shippingDetails.isDefault ? setDefault : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>

    const contents = isButton ? button : card;

    return (
        <React.Fragment>
            <div className={"shippingdetails-card-root-div"}>
                <div style={{width: sizeX + "px", height: sizeY + "px"}} className={"shippingdetails-card-root"}>
                    {contents}
                </div>
            </div>
        </React.Fragment>
    );
}

export default AddressDetailCard;