
import React from 'react';

import "./OrderUpReward.css";

import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';

export default function OrderUpReward(props) {

    const reward = props.reward ? props.reward : {
        id: 1,
        amount: "18.40",
        name: "Loyalty Reward"
    }

    return (
        <React.Fragment>
            <div className={"orderup-reward-root"}>
                <div style={{display: "flex"}}>
                    <div style={{marginLeft: "10px"}} className={"orderup-reward-col-tiny"}>
                        <DiamondOutlinedIcon/>
                    </div>
                    <div className={"orderup-reward-col-large"}>
                        <div style={{fontWeight: 700}}>
                            ${reward.amount}
                        </div>
                        <div>
                            {reward.name}
                        </div>
                    </div>
                    <div className={"orderup-reward-col-tiny orderup-details-bluebold"}>
                        Redeem
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}