
import React from 'react';
import ElHeader from "../../components/header/elheader";
import ElFooter from "../../components/footer/elfooter";

import "./ContactUs.css";
import {Button, TextField, ThemeProvider} from "@mui/material";
import {THEME} from "../../util/ElLocaleUtil";

export default function ContactUs(props) {

    if (!props) {
        console.log("No properties passed");
    }

    return (
        <React.Fragment>
            <ElHeader/>
                <ThemeProvider theme={THEME}>
                <div className={"contactus-root"}>
                    <div className={"contactus-header"}>
                        Contact Us
                    </div>
                    <div className={"contactus-display"}>
                        <div className={"contactus-image-div"}>
                            Reach out and let us know if there's anything we can do for you!
                        </div>
                        <div className={"contactus-column"}>
                            <div className={"contactus-textfield-div-small"}>
                                <TextField
                                    className={"contactus-textfield-small"}
                                    label="Full Name"
                                    defaultValue=""
                                />
                            </div>
                            <div className={"contactus-textfield-div-small"}>
                                <TextField
                                    className={"contactus-textfield-small"}
                                    label="Email*"
                                    defaultValue=""
                                />
                            </div>
                            <div className={"contactus-textfield-div-small"}>
                                <TextField
                                    multiline={true}
                                    className={"contactus-textfield-small"}
                                    label="Message"
                                    defaultValue=""
                                />
                            </div>
                            <div className={"contactus-disclaimer"}>
                                You may receive marketing and promotional materials.  Contact the merchant for their privacy practices.
                            </div>
                            <div className={"contactus-button"}>
                                <Button style={{fontSize: "10px", height: "37px", fontWeight: "700"}} size={"small"} variant={"contained"} color="primary">
                                    <span>Submit</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                </ThemeProvider>
            <ElFooter/>
        </React.Fragment>
    )

}