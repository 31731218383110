
import React from 'react';

import "./ItemDetails.css";
import {FormControl, FormLabel, MenuItem, TextField} from "@mui/material";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
// import ChipContainer from "../../../components/ChipContainer";

export default function ItemDetails(props) {

    if (!props) {
        console.log("No properties passed");
    }

    return (
        <React.Fragment>
            <div className={"itemdetails-root"}>
                <div className={"itemdetails-header"}>
                    Details
                </div>
                <div className={"itemdetails-block"}>
                    <div className={"itemdetails-flexleft"}>
                        <FormControl className={"itemdetails-select"}>
                            <FormLabel style={{textAlign: "left", marginLeft: "5px"}}><span className={"itemdetails-labels"}>Item Type</span></FormLabel>
                            <TextField select className={"itemdetails-select"}>
                                <MenuItem>Physical</MenuItem>
                            </TextField>
                        </FormControl>
                        <FormControl className={"itemdetails-select itemdetails-padtop"}>
                            <FormLabel style={{textAlign: "left", marginLeft: "5px"}}><span className={"itemdetails-labels"}>Item Name</span></FormLabel>
                            <TextField className={"itemdetails-select"}/>
                        </FormControl>
                    </div>
                    <div className={"itemdetails-flexright"}>
                        <div style={{width: "170px", height: "170px", border: "solid 1px"}}>
                            Image
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", border: "solid 1px #DDDDDD", borderRadius: "5px", marginTop: "10px", marginRight: "10px"}}>
                    <FormControl className={"itemdetails-select"} style={{paddingRight: "0px"}}>
                        <FormLabel style={{textAlign: "left", marginLeft: "5px"}}><span className={"itemdetails-labels"}>Item Description</span></FormLabel>
                        <textarea style={{border: "solid 1px #AAAAAA", fontFamily: "Montserrat", resize: "none", flexGrow: 10.0, height: "80px", margin: "0px 5px 5px 5px"}}/>
                    </FormControl>
                </div>
                <div className={"itemdetails-imagednd"}>
                    <div style={{display: "flex", textAlign: "center", margin: "auto", paddingTop: "8px"}}>
                        <div style={{position: "relative", top: "-5x", marginRight: "10px"}}><InsertPhotoIcon/></div>
                        <div style={{fontSize: "14px"}}>Drag and drop images here, &nbsp;
                            <a href={"https://ellocale.com"} style={{fontWeight: 700}}>upload</a>, or &nbsp;
                            <a href={"https://ellocale.com"} style={{fontWeight: 700}}>browse image library</a>
                        </div>
                    </div>
                </div>
                <div style={{paddingRight: "10px", marginTop: "10px"}} >
                    <FormControl className={"itemdetails-select"}>
                        <FormLabel style={{textAlign: "left", marginLeft: "5px"}}><span className={"itemdetails-labels"}>Locations</span></FormLabel>
                        <TextField select className={"itemdetails-select"}>
                            <MenuItem>Societe</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div style={{border: "solid 1px #DDDDDD", marginTop: "10px", marginRight: "10px", height: "130px"}}>
                    <div className={"itemdetails-header-small"}>Categorization</div>
                </div>
            </div>
        </React.Fragment>
    )
}
