
import React from 'react';

import "./TestFrame.css";

function TestFrame(props) {

    let items = props.items ? props.items : [];

    return (
        <React.Fragment>
            <div className={"test-frame"}>
                {
                    items.map((item) => (
                        item
                    ))
                }
            </div>
        </React.Fragment>
    );
}

export default TestFrame;